import { Typography, Link } from '@material-ui/core'


export default function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" target="_blank" href="https://wiseinovacao.com/">
                Wise Inovação ®
            </Link>{' '}
            {new Date().getFullYear()}
            {/* {'.'} */}
        </Typography>
    );
}
