import React from 'react';

import { Alert, AlertTitle } from '@material-ui/lab'
import {
    Snackbar,
} from '@material-ui/core/';

export default function AlertComponent({ open, close, title, body, t }) {
    return (
        <Snackbar open={open} autoHideDuration={6000} onClose={() => close(false)}>
            <Alert onClose={() => close(false)} severity={t}>
                <AlertTitle>{title}</AlertTitle>
                {body}
            </Alert>
        </Snackbar>
    );
}
