import React from 'react';

import { DialogTitle, Toolbar, Typography, Button } from '@material-ui/core'

const screenWidth = window.innerWidth;

function ToolbarAdd({ title, close, save, titleSalvar = "Salvar", titleClose = "Descartar", Cancelar = undefined, salvar = true, color = "#0c053e" }) {
    return (
        <DialogTitle style={{ padding: 0, borderBottom: `4px solid ${color}` }}>
            <Toolbar style={{ display: 'flex', flexDirection: 'row', justifyContent: title ? 'space-between' : 'center' }}>
                {title && <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <Typography variant={screenWidth > 600 ? "text" : "caption2"} style={{ color: '#a7a9aa', fontSize: '16px' }}>{title}</Typography>

                </div>}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button style={{ backgroundColor: "#eff2f5", borderColor: "#eff2f5", color: '#a7a9aa', marginRight: '3px', }} onClick={close} className="p-button-sm">
                        {titleClose}
                    </Button>
                    {Cancelar &&
                        <Cancelar />
                    }
                    {salvar && <Button style={{ backgroundColor: "#ABD036", borderColor: "#ABD036", marginLeft: '3px', color: 'white' }} onClick={save} className="p-button">
                        {titleSalvar}
                    </Button>
                    }
                </div>
            </Toolbar>

        </DialogTitle>
    );
}

export default ToolbarAdd;