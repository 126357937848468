import React, { useState, useEffect } from 'react';

import {
    Calendar,
    Badge,
    List,
    Drawer,
    Descriptions
} from 'antd';

import { getAgenda } from '../../../../services/api'


import "antd/dist/antd.css";
import './styles.css'

function AdminAgendaComponent({ loading }) {
    const [events, setEvents] = useState([]);


    const [drawer, setDrawer] = useState({
        first: {
            open: false,
            title: '-',
            list: [{}]
        },
        second: {
            open: false,
            title: '-',
            item: {}
        }
    })

    async function Agenda() {
        loading(true)
        await getAgenda()
            .then(res => {
                setEvents(res.data)
            })
            .catch(err => console.log(err))
        loading(false)

    }

    useEffect(() => {
        Agenda()
    }, [])

    function getListData(value) {
        let _events = events;

        _events = _events.filter(item => new Date(item.data_entrega)?.getDate() === value.date())
        _events = _events.filter(item => new Date(item.data_entrega)?.getMonth() === value.month())

        _events = _events.filter(item => new Date(item.data_entrega)?.getFullYear() === value.year())


        return _events
    }

    function dateCellRender(value) {
        const listData = getListData(value);
        return (
            <ul className="events">
                {listData?.map(item => {
                    return (
                        <li key={item.numero_pedido}>
                            <Badge status='processing' text={item.cliente.nome} />
                        </li>
                    )
                })}
            </ul>
        );
    }

    function getMonthData(value) {
        if (value.month() === 8) {
            return 1394;
        }
    }

    function monthCellRender(value) {
        const num = getMonthData(value);
        return (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        )
    }

    function onSelect(e) {
        const _list = getListData(e)
        setDrawer({
            ...drawer,
            first: {
                open: true,
                title: `${e._d.getDate()}/${e._d.getMonth() + 1}/${e._d.getFullYear()}`,
                list: _list
            }
        })


    }


    const handleOpenSecond = (item) => {
        setDrawer({
            ...drawer,
            second: {
                open: true,
                title: item?.cliente?.nome,
                item: item,
            }
        })
    }


    return (
        <div>
            <Calendar
                style={{ paddingLeft: '100px', paddingRight: '100px', }}
                dateCellRender={dateCellRender}
                monthCellRender={monthCellRender}
                onChange={(e) => { }}
                onSelect={onSelect}
            />,

            <Drawer
                title={drawer.first.title}
                width={520}
                closable={false}
                onClose={() => setDrawer({
                    first: {
                        open: false,
                        title: '-',
                        list: [{}]
                    },
                    second: {
                        open: false,
                        title: '-',
                        item: {}
                    }
                })}
                visible={drawer.first.open}
            >


                <List
                    dataSource={drawer?.first?.list}
                    bordered
                    locale={{ emptyText: "Não há nada marcado para essa data :(" }}
                    renderItem={(item) => (

                        <List.Item
                            key={item?.id}
                            actions={[
                                <a onClick={() => handleOpenSecond(item)} >
                                    Ver detalhes
                                </a>
                            ]}
                        >
                            <List.Item.Meta
                                title={<Badge status='processing' text={item?.cliente?.nome} />}
                                description={`${item?.servico?.nome}`}

                            />
                        </List.Item>
                    )}
                />

                <Drawer
                    title={drawer.second.title}
                    width={500}
                    closable={false}
                    onClose={() => setDrawer({
                        ...drawer,
                        second: {
                            open: false,
                            title: '',
                            item: {}
                        }
                    })}
                    visible={drawer.second.open}
                >
                    {drawer.second.item &&
                        <Descriptions title={`Pedido Nº ${drawer?.second?.item?.numero_pedido}`} layout="horizontal" bordered>
                            <Descriptions.Item label="Serviço" span={3} >
                                {drawer?.second?.item?.servico?.nome}
                            </Descriptions.Item>
                            <Descriptions.Item label="Status" span={3}>
                                <Badge status="processing" text={drawer?.second?.item?.status ? drawer?.second?.item?.status[0]?.toUpperCase() + drawer?.second?.item?.status.substr(1) : ""} />
                            </Descriptions.Item>
                            <Descriptions.Item label="Horário Marcado" span={3}>
                                {`${new Date(drawer?.second?.item?.data_entrega).getDate()}/${new Date(drawer?.second?.item?.data_entrega).getMonth() + 1}/${new Date(drawer?.second?.item?.data_entrega).getFullYear()} - ${new Date(drawer?.second?.item?.data_entrega).getHours()}:${new Date(drawer?.second?.item?.data_entrega).getMinutes()}:${new Date(drawer?.second?.item?.data_entrega).getSeconds()}`}
                            </Descriptions.Item>
                            <Descriptions.Item label="Endereço" span={3}>
                                {`${drawer?.second?.item?.endereco?.rua}, ${drawer?.second?.item?.endereco?.numero}${drawer?.second?.item?.endereco?.complemento ? ` - ${drawer?.second?.item?.endereco?.complemento}` : undefined}, ${drawer?.second?.item?.endereco?.bairro}`}
                            </Descriptions.Item>
                            <Descriptions.Item label="Cidade/UF" span={3}>
                                {`${drawer?.second?.item?.endereco?.cidade} - ${drawer?.second?.item?.endereco?.estado} (${drawer?.second?.item?.endereco?.cep})`}
                            </Descriptions.Item>

                            <Descriptions.Item label="Produtos">
                                {drawer?.second?.item?.produtos?.map(produto => <text>{produto.nome}<br /></text>)}
                            </Descriptions.Item>
                        </Descriptions>
                    }
                </Drawer>
            </Drawer>
        </div>
    );
}

export default AdminAgendaComponent;