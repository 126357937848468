import React, { useState, useEffect } from 'react';

import { Menubar } from 'primereact/menubar';

import AppbarComponent from '../../components/AppbarComponent'
import SessionComponent from '../../components/SessionComponent'
import Breadcrumb from '../../components/BreadcrumbComponent'

import AdminInicioComponent from './components/Inicio'
import AdminMovimentacaoComponent from './components/Movimentacao'
import AdminProdutoComponent from './components/Produto'
import AdminServicoComponent from './components/Servico'
import AdminListarPedidosComponent from './components/ListarPedidos'
import AdminProfileComponent from './components/Profile'
import AdminAgendaComponent from './components/Agenda'

import LoadingComponent from '../../components/LoadingComponent'

import { useLocation, useHistory } from "react-router-dom";

import { useStyles } from './styles'
import './styles.css'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Admin() {
    let query = useQuery();
    const history = useHistory()
    const classes = useStyles();
    const [activeComponent, setActiveComponent] = useState('')

    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [session, setSession] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const c = query.get('c')
        const h = query.get('h')

        if (c === 'inicio') {
            setActiveComponent('AdminInicioComponent')
            setBreadcrumbs([])
        } else if (c === 'produtos') {
            if (h === 'produtos') {
                setActiveComponent('AdminProdutoComponent')
                setBreadcrumbs([{
                    title: 'Produtos'
                }])
            }
            if (h === 'movimentacoes') {
                setActiveComponent('AdminMovimentacaoComponent')
                setBreadcrumbs([{

                    title: 'Movimentações'
                }])
            }

        } else if (c === 'servicos') {
            if (h === 'servicos') {
                setActiveComponent('AdminServicoComponent')
                setBreadcrumbs([{
                    title: 'Serviços'
                }])

            }

        } else if (c === 'perfil') {
            setActiveComponent('AdminProfileComponent')
            setBreadcrumbs([{
                title: 'Perfil'
            }])
        } else if (c === "agenda") {
            setActiveComponent('AdminAgendaComponent')
            setBreadcrumbs([{
                title: 'Agenda'
            }])
        } else if (c === "pedidos") {
            setActiveComponent('AdminListarPedidosComponent')
            setBreadcrumbs([{
                title: 'Pedidos'
            }])
        } else {
            history.push("/admin?c=pedidos")
        }
    }, [query.get('c'), query.get('h')])

    return (
        <div className={classes.root}>
            <AppbarComponent
                Item={Menubar}
                login={true}
            />
            <Breadcrumb item={breadcrumbs} />
            {activeComponent === 'AdminInicioComponent' &&
                <AdminInicioComponent
                    session={setSession}
                    loading={setLoading}
                />
            }
            {activeComponent === 'AdminMovimentacaoComponent' &&
                <AdminMovimentacaoComponent
                    session={setSession}
                    loading={setLoading}
                />
            }
            {activeComponent === 'AdminProdutoComponent' &&
                <AdminProdutoComponent
                    session={setSession}
                    loading={setLoading}
                />
            }
            {activeComponent === 'AdminServicoComponent' &&
                <AdminServicoComponent
                    session={setSession}
                    loading={setLoading}
                />
            }
            {activeComponent === 'AdminListarPedidosComponent' &&
                <AdminListarPedidosComponent
                    session={setSession}
                    loading={setLoading}
                />
            }
            {activeComponent === 'AdminProfileComponent' &&
                <AdminProfileComponent
                    session={setSession}
                    loading={setLoading}
                />
            }
            {activeComponent === 'AdminAgendaComponent' &&
                <AdminAgendaComponent
                    session={setSession}
                    loading={setLoading}
                />
            }
            <LoadingComponent open={loading} />
            <SessionComponent open={session} />
        </div >
    );

}

export default Admin;