import axios from 'axios'


// const baseURL = 'http://127.0.0.1:8000'
// const baseURL = 'https://teste-wiseservico.herokuapp.com'
const baseURL = 'https://wiseservico.herokuapp.com'


// Users


export async function getUser() {
    const response = axios.get(`${baseURL}/user/`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            'Content-Type': 'application/json',
        },
    })
    return response
}

export async function updateUser(id, formData) {


    const response = axios.patch(`${baseURL}/user/${id}/`, formData, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            "Content-Type": "multipart/form-data;",
        },
    })


    return response
}


// Login

export async function getToken(username, password) {
    const user = JSON.stringify({ username, password });

    const response = await axios.post(`${baseURL}/login/`, user, {
        headers: {
            'Content-Type': 'application/json',
        }
    })

    return response;

}


// Produtos

export async function getProdutos() {
    const response = axios.get(`${baseURL}/produtos/`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            'Content-Type': 'application/json',
        },
    })


    return response
}

export async function getEmpresaProdutos(empresa) {

    let url = `${baseURL}/produtos/`

    if (empresa) {
        url += `?empresa=${empresa}`
    }
    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    })


    return response
}

export async function saveProduto(data) {
    const response = axios.post(`${baseURL}/produtos/`, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            "Content-Type": "multipart/form-data;",
        },
    })


    return response
}

export async function editProduto(id, data) {


    const response = axios.patch(`${baseURL}/produtos/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            "Content-Type": "multipart/form-data;",
        },
    })


    return response

}


// Serviços

export async function getServicos() {

    const response = axios.get(`${baseURL}/servicos/`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            'Content-Type': 'application/json',
        },
    })

    return response
}


export async function getServicosEmpresa(empresa, servico) {


    let url = `${baseURL}/servicos/`

    if (empresa) {
        url += `?empresa=${empresa}`
    }

    if (servico) {
        url += `&servico=${servico}`
    }

    const response = axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return response
}

export async function createServico(data) {
    const response = axios.post(`${baseURL}/servicos/`, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            "Content-Type": "multipart/form-data;",
        },
    })

    return response
}

export async function updateServico(id, data) {


    const response = axios.patch(`${baseURL}/servicos/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            "Content-Type": "multipart/form-data;",
        },
    })

    return response
}


// Movimentações

export async function getMovimentacoes() {
    const response = axios.get(`${baseURL}/movimentacoes/`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            'Content-Type': 'application/json',
        },
    })

    return response
}


export async function saveMovimentacao(data) {
    const response = axios.post(`${baseURL}/movimentacoes/`, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            'Content-Type': 'application/json',
        },
    })

    return response
}



// Pesquisar empresa
export async function getEmpresa(id) {
    const response = axios.get(`${baseURL}/empresas/${id}/`)

    return response
}

// Pedidos (Serviços)


export async function getPedidos() {
    const response = await axios.get(`${baseURL}/solicitacoes/`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            'Content-Type': 'application/json',
        },
    })

    return response
}



export async function addPedido(data) {

    const response = axios.post(`${baseURL}/solicitacoes/`, data)

    return response
}

export async function editarPedido(data) {
    const { id } = data
    const response = await axios.patch(`${baseURL}/solicitacoes/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            'Content-Type': 'application/json',
        },
    })

    return response
}


export async function consultarPedido(pedido) {
    const { num } = pedido
    let url = `${baseURL}/consultar/`

    if (num) {
        url += `?num=${num}`
    }

    const response = await axios.get(url, {
        headers: {
            'Content-Type': 'application/json',
        },
    })

    return response

}

export async function getCEP(cep) {
    const response = axios.get(`https://ws.apicep.com/cep/${cep}.json`, {
        headers: {
            'Content-Type': 'application/json',

        },
    })

    return response

}


// Agenda
export async function getAgenda() {
    const response = await axios.get(`${baseURL}/agenda/`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('tokenAccess')}`,
            'Content-Type': 'application/json',

        },
    })

    return response

}