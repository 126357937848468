import React, { useEffect, useState, useRef } from 'react';
import {
    Typography,
    Breadcrumbs,
    Dialog,
    DialogContent,
    TextField,
    Switch,
    FormControl,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    Avatar
} from '@material-ui/core/';

import ToolbarAdd from '../../../../../../components/ToolbarAddComponent'
import AlertDiscardComponent from '../../../../../../components/AlertDiscardComponent';
import AlertComponent from '../../../../../../components/AlertComponent';
import ConfirmComponent from './../../../../../../components/ConfirmComponent';

import { FileUpload } from "primereact/fileupload";

import { useStyles } from './styles.js';
import { editProduto } from '../../../../../../services/api'

import { formataDinheiro } from '@wiseinovacao/util'

import './styles.css';

export default function Adicionar({ open, close, atualizar, item }) {
    const classes = useStyles();
    const [confirmDiscard, setConfirmDiscard] = useState(false)

    const [image, setImage] = useState();

    const [id, setID] = useState(0)
    const [nome, setNome] = useState('')
    const [codigo, setCodigo] = useState('')
    const [valor, setValor] = useState(0)
    const [ativo, setAtivo] = useState(true)
    const [descricao, setDescricao] = useState('')
    const [mostrarValor, setMostrarValor] = useState(true)
    const [openDisable, setOpenDisable] = useState(false)


    const [imagemItem, setImageItem] = useState()

    const [openAlert, setOpenAlert] = useState(false)
    const [itemError, setItemError] = useState('')
    const [error, setError] = useState({
        title: 'Error Title',
        body: 'Body Title',
        t: 'error'
    })

    const fileUploadRef = useRef(null);
    const onUpload = ({ files }) => {
        const [file] = files
        setImage(file)
        fileUploadRef.current.clear() //Limpa o arquivo da foto
    };

    useEffect(() => {
        setID(item.id)
        setNome(item.nome)
        setCodigo(item.codigo_interno)
        setValor(formataDinheiro(parseFloat(item.preco).toFixed(2)))
        setAtivo(item.ativo)
        setImageItem(item.imagem)
        setMostrarValor(item.mostrar_preco)
        setDescricao(item.descricao)
    }, [open])


    function clearAll() {
        setConfirmDiscard(false)
        close(false)
        setID()
        setNome('')
        setCodigo('')
        setValor(0)
        setAtivo(true)
        setDescricao('')
        setMostrarValor(true)
        setImage()

    }

    async function salvarProduto() {

        if (!nome || nome === '' || nome.length < 3) {
            setItemError("nome")
            setError({
                title: 'Nome inválido',
                body: 'É necessário informar um nome valido para o produto',
                t: 'error'
            })

            return setOpenAlert(true)
        }
        if (!descricao || descricao === '' || descricao.length < 3) {
            setItemError("descricao")
            setError({
                title: 'Descrição inválida!',
                body: 'É necessário informar uma descrição valida para o produto',
                t: 'error'
            })

            return setOpenAlert(true)
        }

        let _valor = String(valor)
        _valor = _valor?.replace(".", "")
        _valor = _valor?.replace(",", ".")

        if (!_valor) {
            setItemError("valor")
            setError({
                title: 'Valor inválido!',
                body: 'É necessário informar um valor para o produto!',
                t: 'error'
            })

            return setOpenAlert(true)
        }

        let formData = new FormData()

        formData.append('id', id)

        formData.append('codigo_interno', codigo)

        formData.append('nome', nome)



        formData.append('preco', parseFloat(_valor))

        formData.append('mostrar_preco', mostrarValor)
        formData.append('ativo', ativo)
        formData.append('descricao', descricao)

        if (image) {
            formData.append('imagem', image)
        }


        await editProduto(id, formData)
            .then(res => {
                atualizar()
                setError({
                    title: "Sucesso",
                    body: "Produto editado com sucesso",
                    t: "success"
                })
                setOpenAlert(true)
                close(false)
            })
            .catch(err => console.log(err))
    }


    function handleClose() {
        if (nome != item.nome || codigo != item.codigo_interno || valor != item.preco || ativo != item.ativo || mostrarValor != item.mostrar_preco || descricao != item.descricao) {
            return setConfirmDiscard(true)
        } else {
            clearAll()
        }
    }

    const chooseOptions = {
        icon: "pi pi-fw pi-image",
        label: "Editar foto",
        className: "custom-choose-btn p-button-rounded p-button-outlined"
    };


    const handleSetAtivo = () => {

        if (!ativo) {
            setAtivo(true)
        } else {
            setOpenDisable(true)
        }
    }

    return (
        <>
            <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose} >

                <ToolbarAdd title="Editar produto" close={handleClose} save={salvarProduto} />

                <DialogContent style={{ backgroundColor: '#EFF2F5' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <div style={{ width: '100%' }}>
                            <Breadcrumbs aria-label="breadcrumb" style={{ color: '#3f51b5' }}>
                                <Typography style={{ color: '#a7a9aa' }}>Produto</Typography>
                                <Typography style={{ color: '#a7a9aa' }}>{nome}</Typography>

                            </Breadcrumbs>
                            <div className={classes.divInformation}>
                                <Typography style={{ color: '#323c4b' }}>Dados do produto</Typography>

                                <div style={{ display: 'flex', width: '100%' }}>
                                    <TextField
                                        className={classes.input}
                                        label="Nome do produto"
                                        required
                                        fullWidth
                                        error={itemError === 'nome'}
                                        variant="outlined"
                                        value={nome}
                                        onChange={(e) => {
                                            if (itemError === 'nome') {
                                                setItemError('')
                                            }
                                            setNome(e.target.value)
                                        }}
                                    />
                                    <TextField
                                        className={classes.input}
                                        style={{ width: '40%' }}
                                        label="Código Interno"
                                        // required
                                        fullWidth
                                        error={itemError === 'codigo'}
                                        variant="outlined"
                                        value={codigo}
                                        onChange={(e) => {
                                            if (itemError === 'codigo') {
                                                setItemError('')
                                            }
                                            setCodigo(e.target.value)
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', width: '100%' }}>

                                    <TextField
                                        className={classes.input}
                                        label="Descrição do produto"
                                        required
                                        fullWidth
                                        error={itemError === 'descricao'}
                                        variant="outlined"
                                        value={descricao}
                                        onChange={(e) => {
                                            if (itemError === 'descricao') {
                                                setItemError('')
                                            }
                                            setDescricao(e.target.value)
                                        }}
                                    />
                                    <TextField
                                        className={classes.input}
                                        style={{ width: '40%' }}
                                        label="Valor"
                                        required
                                        fullWidth
                                        error={itemError === 'valor'}
                                        variant="outlined"
                                        value={valor}
                                        onChange={(e) => {
                                            if (itemError === 'valor') {
                                                setItemError('')
                                            }

                                            setValor(formataDinheiro(e.target.value))
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                    <div
                                        className={classes.input}
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={ativo}
                                                            color="primary"
                                                            onChange={() => handleSetAtivo()}
                                                            name="mostrar"
                                                        />
                                                    }
                                                    label="Produto ativo"
                                                />
                                            </FormGroup>
                                            <FormHelperText>Quando ativo o serviço aparecerá ao usuário</FormHelperText>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={mostrarValor}
                                                            color="primary"
                                                            onChange={() => setMostrarValor(!mostrarValor)}
                                                            name="mostrar"
                                                        />
                                                    }
                                                    label="Mostrar valor"
                                                />
                                            </FormGroup>
                                            <FormHelperText>Quando ativo será mostrado o valor ao solicitar o serviço</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                        <Avatar variant="rounded" src={image?.objectURL ? image.objectURL : imagemItem} alt={nome} id="image" />

                                        <FileUpload
                                            ref={fileUploadRef}
                                            mode="basic"
                                            chooseOptions={chooseOptions}
                                            customUpload
                                            onSelect={onUpload}
                                            uploadHandler={onUpload}
                                            accept="image/*"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <ConfirmComponent
                open={openDisable}
                close={setOpenDisable}
                title="Confirmar"
                body={`Ao desativar o produto, o mesmo será demovido de todos os serviços vinculados.`}
                action={() => {
                    setAtivo(false)
                    setOpenDisable(false)
                }}
            />


            <AlertDiscardComponent
                open={confirmDiscard}
                cancel={setConfirmDiscard}
                clear={clearAll}
            />
            <AlertComponent
                open={openAlert}
                close={setOpenAlert}
                title={error.title}
                body={error.body}
                t={error.t}
            />
        </>
    );
}
