import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#333A44',
        },
    },

    props: {
        MuiAccordionSummary: {
            expandIcon: {
                props: {
                    '&$expanded': {
                        transform: 'rotate(0deg)',
                    },
                },
            },
        },
    },
});
