import Firebase from 'firebase/app';
import 'firebase/remote-config';
import 'firebase/analytics'

const firebaseConfig = {
    apiKey: "AIzaSyCFb1LLhy6HUImpRlDYOMyuW9JINeSl0mo",
    authDomain: "wise-servico.firebaseapp.com",
    projectId: "wise-servico",
    storageBucket: "wise-servico.appspot.com",
    messagingSenderId: "639992602467",
    appId: "1:639992602467:web:7b6a0ae663cc129a8c3d56",
    measurementId: "G-YPRGMBMF47"
};


export const firebase = Firebase.initializeApp(firebaseConfig);
