import React, { useState, useEffect } from 'react';
import {
    Grid,
    Typography,
    ListItem,
    ListItemText,
    Divider,
    Button as ButtonMaterial,
} from '@material-ui/core'

import { useStyles } from './styles'

import { AutoComplete } from 'primereact/autocomplete'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'

import { getEmpresaProdutos } from '../../../../services/api'

import './styles.css'

import {
    useLocation
} from "react-router-dom";


function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const screenWidth = window.innerWidth;

export default function ProdutoForm({ infos, setInfos, setOpenAlert, setMessageAlert, error, setError }) {
    let query = useQuery();

    const classes = useStyles();
    const [filteredItem, setFilteredItem] = useState(null)

    const [total, setTotal] = useState(0.0)

    const [allProdutos, setAllProdutos] = useState()


    async function Produtos() {
        await getEmpresaProdutos(query.get('empresa'))
            .then(res => {
                setAllProdutos(res.data)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        Produtos()
    }, [query.get('empresa')])

    useEffect(() => {
        let total = 0.0;
        let itens = infos?.servico?.produtos ? infos.servico.produtos : [];

        itens.map(element => {
            if (element.id) {
                let preco = element.quantidade * element.preco
                total += preco
            }
        })
        setTotal(total)
    }, [infos?.servico?.produtos, infos?.servico])


    function handleAddNewItem() {
        let itensCopy = Array.from(infos.servico.produtos);
        itensCopy.push(
            {
                isNew: true,
            }
        )
        setInfos({
            ...infos,
            servico: {
                ...infos.servico,
                produtos: itensCopy
            }

        })
    }

    const searchItem = (event) => {
        setTimeout(() => {
            if (!allProdutos) {
                return
            }
            let _filteredItems;

            if (!event.query.trim().length) {
                if (allProdutos) {
                    _filteredItems = [...allProdutos];
                }
            } else {
                _filteredItems = allProdutos.filter((item) => {
                    return item.nome
                        .toLowerCase()
                        .startsWith(event.query.toLowerCase());
                });
            }
            setFilteredItem(_filteredItems);
        }, 250);
    };


    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Produtos e detalhes
            </Typography>
            <Grid container spacing={3} direction="column">
                {infos?.servico?.produtos.map((product, index) => {
                    if (product.isNew) {
                        return (
                            <ListItem className={classes.listItem} key={product?.name} >
                                <div className={screenWidth > 600 ? classes.divListItem : classes.divListItemMobile} >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

                                        <AutoComplete
                                            id="autocomplete"
                                            dropdown
                                            placeholder="Produto"
                                            field="nome"
                                            value={product?.nome}
                                            suggestions={filteredItem}
                                            completeMethod={e => searchItem(e)}
                                            onSelect={(e) => {
                                                let produtos = infos?.servico?.produtos
                                                produtos[index] = {
                                                    ...e.value,
                                                    isNew: true,
                                                    quantidade: 1,
                                                    editable: true,
                                                }
                                                setInfos({
                                                    ...infos,
                                                    servico: {
                                                        ...infos.servico,
                                                        produtos: produtos
                                                    }
                                                })
                                            }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '45%', margin: screenWidth < 600 ? 10 : 0 }}>

                                        <InputText
                                            style={{
                                                width: screenWidth < 600 ? '150px' : '70px',
                                                borderColor: error === 'quantidade' ? 'red' : undefined,
                                                borderWidth: 1.2
                                            }}
                                            name="quantidade"
                                            placeholder="Quantidade"
                                            value={product?.quantidade}
                                            onChange={e => {
                                                if (error === 'quantidade' && e.target.value > 0) {
                                                    setError('')
                                                }
                                                let produtos = infos?.servico?.produtos
                                                produtos[index] = {
                                                    ...produtos[index],
                                                    isNew: true,
                                                    quantidade: e.target.value
                                                }
                                                setInfos({
                                                    ...infos,
                                                    servico: {
                                                        ...infos.servico,
                                                        produtos: produtos
                                                    }
                                                })
                                            }}
                                        />
                                        <span
                                            className="p-inputgroup-addon"
                                            style={{
                                                borderTopColor: error === 'quantidade' ? 'red' : undefined,
                                                borderRightColor: error === 'quantidade' ? 'red' : undefined,
                                                borderBottomColor: error === 'quantidade' ? 'red' : undefined,
                                                borderWidth: 1.2

                                            }}
                                        >x</span>
                                    </div>
                                    <div style={{ display: 'flex', alignItems: 'center', width: '35%', justifyContent: 'center' }}>

                                        <span className="p-inputgroup-addon">R$</span>
                                        <InputText
                                            name="preco"
                                            disabled
                                            style={{ width: screenWidth > 600 ? '50%' : "80%" }}
                                            placeholder="Preço"
                                            value={product?.preco?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                            <Button
                                                id="button-adicionar"
                                                icon="pi pi-check"
                                                onClick={() => {
                                                    let _produtos = infos?.servico?.produtos
                                                    let newItem = {
                                                        ..._produtos[index],
                                                        isNew: false,
                                                    }
                                                    if (newItem.quantidade <= 0) {
                                                        setMessageAlert({
                                                            title: "Quantidade inválida",
                                                            body: "A quantidade do produto não pode ser menor ou igual a 0!",
                                                            t: 'error'
                                                        })
                                                        setError('quantidade')
                                                        return setOpenAlert(true);
                                                    } else {
                                                        _produtos[index] = newItem
                                                        setInfos({
                                                            ...infos,
                                                            servico: {
                                                                ...infos.servico,
                                                                produtos: _produtos
                                                            }
                                                        })
                                                    }

                                                }}
                                            />
                                            <Button
                                                style={{ margin: 12 }}
                                                id="button-remover"
                                                icon="pi pi-times"
                                                onClick={() => {
                                                    let _produtos = infos?.servico?.produtos
                                                    _produtos = _produtos.filter(element => _produtos.indexOf(element) != index)
                                                    setInfos({
                                                        ...infos,
                                                        servico: {
                                                            ...infos.servico,
                                                            produtos: _produtos
                                                        }
                                                    })
                                                }}
                                            />

                                        </div>
                                    </div>
                                </div>

                            </ListItem>
                        )
                    } else {
                        return (
                            <ListItem className={classes.listItem} key={product?.nome}>
                                <img src={product.imagem} style={{ width: '90px', marginRight: 12, borderRadius: 1.5 }} />

                                <ListItemText primary={product?.nome} secondary={product?.descricao} />
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100%' }}>
                                    {product?.mostrar_preco &&
                                        <Typography variant="body2"> {product.quantidade} x {(product?.preco)?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>
                                    }

                                    {product.editable && <Button
                                        style={{ margin: 12 }}
                                        id="button-editar"
                                        icon="pi pi-pencil"
                                        onClick={() => {
                                            let _produtos = infos?.servico?.produtos
                                            let newItem = {
                                                ..._produtos[index],
                                                isNew: true,
                                            }
                                            _produtos[index] = newItem
                                            setInfos({
                                                ...infos,
                                                servico: {
                                                    ...infos.servico,
                                                    produtos: _produtos
                                                }
                                            })
                                        }}
                                    />}
                                </div>
                            </ListItem>
                        )
                    }


                })}
                <div
                    style={{ padding: 12, display: 'flex', justifyContent: 'space-between' }}
                >
                    <ButtonMaterial
                        style={{
                            backgroundColor: infos?.servico?.produtos[infos?.servico?.produtos?.length - 1]?.isNew ? "gray" : "#1d51b5", borderColor: "#1d51b5", color: '#fff', marginRight: '3px',
                        }}
                        onClick={() => handleAddNewItem()}
                        disabled={infos?.servico?.produtos[infos?.servico?.produtos?.length - 1]?.isNew}
                        className="p-button-sm">
                        Adicionar
                    </ButtonMaterial>
                    <text><b>Total: </b>{total?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</text>
                </div>
                <Divider />

            </Grid>
        </React.Fragment >
    );
}
