import React, { useState, useEffect } from 'react';

import {
  Container,
  Typography,
  IconButton,
  Avatar
} from '@material-ui/core';

import { Input, Divider } from 'antd';
import { Timeline } from "primereact/timeline";
import { Card } from "primereact/card";

import AlertComponent from '../../components/AlertComponent'
import AppBarComponent from '../../components/AppbarComponent'

import LocationOnIcon from '@material-ui/icons/LocationOn';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import RoomIcon from '@material-ui/icons/Room';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import { adicionaZero } from './../../utils/functions';
import { consultarPedido } from '../../services/api'
import { useLocation } from "react-router-dom";
import { useStyles } from './styles'

import './styles.css'
import "antd/dist/antd.css";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const { Search } = Input;
const screenWidth = window.innerWidth;

export default function ConsultarServico() {
  const classes = useStyles();

  let query = useQuery()

  const [loading, setLoading] = useState(false)
  const [num, setNum] = useState('')
  const [pedido, setPedido] = useState()
  const [openAlert, setOpenAlert] = useState(false)
  const [error, setError] = useState({
    title: 'Error Title',
    body: 'Body Title',
    t: 'error'
  })

  const customizedMarker = (item) => {
    return (
      <span
        className="custom-marker p-shadow-2"
        style={{
          backgroundColor:
            item.status === "Solicitado" ? "#9C27B0" :
              item.status === "Aceito" ? "#673AB7" :
                item.status === "Cancelado" ? "red" :
                  item.status === "Concluído" ? "green"
                    : undefined, textAlign: 'center'
        }}
      >
        <i
          className={
            item.status === "Solicitado" ? "pi pi-info-circle" :
              item.status === "Aceito" ? "pi pi-clock" :
                item.status === "Cancelado" ? "pi pi-ban" :
                  item.status === "Concluído" ? "pi pi-check" :

                    undefined}></i>
      </span>
    );
  };

  const customizedContent = (item) => {
    const _data = new Date(item.data)
    const _data_marcada = item.data_marcada ? new Date(item.data_marcada) : undefined

    return (
      <Card title={item?.status} subTitle={`${adicionaZero(_data.getDate())}/${adicionaZero(_data.getMonth() + 1)}/${_data.getFullYear()} - ${adicionaZero(_data.getHours())}:${adicionaZero(_data.getMinutes())}:${adicionaZero(_data.getSeconds())}`}>
        <Typography>{item.descricao}.</Typography>
        {_data_marcada &&
          <text>Sua solicitação está marcada para <br /><b>{adicionaZero(_data_marcada.getDate())}/{adicionaZero(_data_marcada.getMonth() + 1)}/{_data_marcada.getFullYear()} - {adicionaZero(_data_marcada.getHours())}:{adicionaZero(_data_marcada.getMinutes())}</b></text>
        }
      </Card>
    );
  };

  useEffect(() => {
    let _num = query.get('num')
    if (_num) {
      handleConsulta(_num)
      setNum(_num)
    }

  }, [query.get('num')])

  async function handleGetCampanha() {
  }

  async function handleConsulta(numero) {
    setLoading(true)
    const data = {
      num: numero
    }

    await consultarPedido(data)
      .then(res => {

        if (res.data[0]) {
          setPedido(res.data[0])
        } else {
          setOpenAlert(true)
          setError({
            title: "Código não encontrado",
            body: "Não encontramos seu código, favor corrigir e tentar novamente",
            t: 'error'
          })
        }
      })
      .catch(err => console.log(err))
    setLoading(false)

  }

  useEffect(() => {
    handleGetCampanha()

  }, [])

  return (
    <div className={classes.root}>
      <AppBarComponent />
      <Container maxWidth="sm" >
        <div id="div-content">
          <Search
            value={num}
            onChange={(e) => setNum(e.target.value)}
            placeholder="Digite o número da solicitação"
            enterButton
            onSearch={(value) => handleConsulta(value)}
            size="large"
            loading={loading}
          />
        </div>
        {pedido &&
          <div>
            <Card id="card">
              <Divider orientation="left" plain style={{ marginTop: -12 }}>Empresa</Divider>

              <div id={screenWidth < 600 ? "container-mobile-consultar" : "container-consultar"}>
                <div elevation={1} id={screenWidth < 600 ? "card-empresa-mobile-consultar" : "card-empresa-consultar"}>
                  <Avatar alt={pedido?.clientewise?.nome} src={pedido?.clientewise?.logo} id={screenWidth < 600 ? "imagem-perfil-mobile-consultar" : "imagem-perfil-consultar"} />

                  <div>
                    <Typography variant="h5" component="h2">
                      {pedido?.clientewise?.nome}
                    </Typography>
                    <div style={{ display: 'flex', marginBottom: '5px', marginTop: '5px' }}>
                      <RoomIcon fontSize="small" color="action" />
                      <Typography variant="body2" component="h2" color="textSecondary">
                        {pedido?.clientewise?.cidade}
                      </Typography>
                    </div>
                    <Typography className={classes.pos} variant="body2" color="textSecondary">
                      CNPJ: {pedido?.clientewise?.cnpj}
                    </Typography>
                    <div id="div-icons">
                      <div>
                        <IconButton href={`https://api.whatsapp.com/send?phone=55${pedido?.clientewise?.whatsapp ? pedido?.clientewise?.whatsapp.replace(/[() -]/g, '') : '-'}`} target="_blank" size="small" style={{ color: "#34af23" }}>
                          <WhatsAppIcon />
                        </IconButton>
                        <IconButton href={pedido?.clientewise?.facebook} target="_blank" size="small" style={{ color: "#3b5998" }}>
                          <FacebookIcon />
                        </IconButton>
                        <IconButton href={pedido?.clientewise?.instagram} target="_blank" size="small" style={{ color: "#e95950" }}>
                          <InstagramIcon />
                        </IconButton>
                        <IconButton href={`mailto:${pedido?.clientewise?.email}`} target="_blank" size="small" style={{ color: 'grey' }}>
                          <AlternateEmailIcon />
                        </IconButton>
                      </div>

                      {screenWidth > 600 &&
                        <div id="div-descricao-consultar">
                          <Typography variant="body1">
                            {pedido?.clientewise?.descricao}
                          </Typography>
                        </div>
                      }
                    </div>

                  </div>
                </div>
                {screenWidth < 600 &&
                  <div id="div-descricao-mobile-consultar">

                    <Typography variant="body1">
                      {pedido?.clientewise?.descricao}
                    </Typography>
                  </div>
                }
              </div>



              <Divider orientation="left" plain style={{ marginTop: -30 }}>Serviço</Divider>

              <Typography variant="h5" component="h2">{pedido?.servico?.nome}</Typography>
              <Typography variant="body1" component="h2" color="textSecondary">{pedido?.servico?.descricao}</Typography>
              <Typography variant="body1" component="h2" color="textSecondary">Status: {pedido?.status}</Typography>

              <Divider orientation="left" plain>Solicitante</Divider>

              <div style={{ display: 'flex' }}>
                <AccountCircleIcon style={{ margin: '2px' }} color="textSecondary" />
                <Typography
                  variant="body1"
                  component="h2"
                  color="textSecondary"
                >{`${pedido?.cliente?.nome} (${pedido?.cliente?.tipo === "fisica" ? `${pedido?.cliente?.identificacao.substring(0, 3)}.***.***-${pedido?.cliente?.identificacao.substring(12, 14)}` : `${pedido?.cliente?.identificacao.substring(0, 2)}.***.***/****-${pedido?.cliente?.identificacao.substring(16, 18)}`})`}

                </Typography>
              </div>
              <div style={{ display: 'flex' }}>
                <LocationOnIcon style={{ margin: '2px' }} color="textSecondary" />
                <Typography variant="body1" component="h2" color="textSecondary">{`${pedido?.endereco?.rua}, ${pedido?.endereco?.numero}${pedido?.endereco?.complemento ? `-${pedido.endereco.complemento}` : ""}, ${pedido?.endereco?.bairro}, ${pedido?.endereco?.cidade} - ${pedido?.endereco?.estado} (${pedido?.endereco?.cep})`}</Typography>
              </div>

              <Divider orientation="left" plain>Histórico</Divider>
              <div className="timeline-demo">
                <Timeline
                  value={pedido?.historico}
                  align="alternate"
                  className="customized-timeline"
                  marker={customizedMarker}
                  content={customizedContent}
                />
              </div>
            </Card>
          </div>
        }
        <AlertComponent
          open={openAlert}
          close={setOpenAlert}
          title={error.title}
          body={error.body}
          t={error.t}
        />
      </Container>
    </div >
  );
}
