import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    input: {
        margin: '6px',
    },
    card: {
        margin: '20px',
        padding: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'center',
        alignSelf: 'center',
        width: '280px',
        height: '280px',
    },
    pos: {
        marginBottom: 12,
    },
}));

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#333A44',
        },
    },

    props: {
        MuiAccordionSummary: {
            expandIcon: {
                props: {
                    '&$expanded': {
                        transform: 'rotate(0deg)',
                    },
                },
            },
        },
    },
});
