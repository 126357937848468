import React, { useEffect, useState } from 'react';
import {
    Typography,
    Breadcrumbs,
    Dialog,
    DialogContent,
    TextField,
} from '@material-ui/core/';

import { Autocomplete } from '@material-ui/lab'

import ToolbarAdd from '../../../../../../components/ToolbarAddComponent'
import AlertDiscardComponent from '../../../../../../components/AlertDiscardComponent';
import AlertComponent from '../../../../../../components/AlertComponent';

import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import './styles.css';

import { useStyles } from './styles.js';

import { saveMovimentacao, getProdutos } from '../../../../../../services/api'

export default function Adicionar({ open, close, atualizar }) {
    const classes = useStyles();
    const [confirmDiscard, setConfirmDiscard] = useState(false)

    const [tipo, setTipo] = useState('')
    const [descricao, setDescricao] = useState('')
    const [produto, setProduto] = useState()
    const [quantidade, setQuantidade] = useState(0)

    const [produtos, setProdutos] = useState({})

    const [inputValue, setInputValue] = useState()
    const [inputValue2, setInputValue2] = useState()


    const [openAlert, setOpenAlert] = useState(false)
    const [itemError, setItemError] = useState('')
    const [error, setError] = useState({
        title: 'Error Title',
        body: 'Body Title',
        t: 'error'
    })

    function clearAll() {
        setConfirmDiscard(false)
        close(false)
        setTipo('')
        setDescricao('')
        setProduto({})
        setQuantidade(0)
    }


    function handleClose() {
        if (tipo != '' || descricao != '' || produto?.id || quantidade > 0) {
            return setConfirmDiscard(true)
        } else {
            clearAll()
        }
    }

    async function Produtos() {
        await getProdutos()
            .then(res => setProdutos(res.data))
            .catch(err => console.log(err))
    }

    useEffect(() => {
        Produtos()
    }, [])



    async function salvarMovimentacao() {

        if (!tipo) {

            setItemError('tipo')
            setError({
                title: "Tipo inválido!",
                body: 'É necessário informar o tipo de movimentação!',
                t: 'error'
            })
            return setOpenAlert(true)
        }

        if (!produto?.id) {

            setItemError('produto')
            setError({
                title: "Produto inválido",
                body: 'É necessário informar um produto cadastrado!',
                t: 'error'
            })
            return setOpenAlert(true)
        }

        if (!quantidade || quantidade <= 0) {

            setItemError('quantidade')
            setError({
                title: "Quantidade inválida",
                body: 'É necessário informar uma quantidade válida!',
                t: 'error'
            })
            return setOpenAlert(true)
        }



        const data = {
            tipo: tipo,
            descricao: descricao,
            produto: produto.id,
            quantidade: quantidade
        }

        await saveMovimentacao(data)
            .then(() => {
                atualizar()
                setError({
                    title: "Sucesso",
                    body: "Movimentação adicionado com sucesso",
                    t: "success"
                })
                setOpenAlert(true)
                clearAll()
            })
            .catch(err => console.log(err))
    }

    return (
        <>
            <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose} >

                <ToolbarAdd title="Adicionar movimentação" close={handleClose} save={salvarMovimentacao} />

                <DialogContent style={{ backgroundColor: '#EFF2F5' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <div style={{ width: '100%' }}>
                            <Breadcrumbs aria-label="breadcrumb" style={{ color: '#3f51b5' }}>
                                <Typography style={{ color: '#a7a9aa' }}>Movimentação</Typography>
                                <Typography style={{ color: '#a7a9aa' }}>{tipo}</Typography>

                            </Breadcrumbs>
                            <div className={classes.divInformation}>
                                <Typography style={{ color: '#323c4b' }}>Dados do produto</Typography>
                                <div style={{ display: 'flex' }}>

                                    <Autocomplete
                                        className={classes.input}
                                        fullWidth
                                        value={tipo}
                                        onChange={(event, newValue) => {
                                            if (itemError === 'tipo') {
                                                setItemError('')
                                            }
                                            setTipo(newValue);
                                        }}
                                        inputValue={inputValue}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue(newInputValue);
                                        }}
                                        getOptionLabel={(option) => option}
                                        options={['Entrada', 'Saída']}
                                        noOptionsText="Sem opções"
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} error={itemError === 'tipo'} required label="Tipo de movimentação" variant="outlined" />}
                                    />
                                </div>


                                <div style={{ display: 'flex' }}>

                                    <Autocomplete
                                        className={classes.input}
                                        style={{ width: '70%' }}

                                        fullWidth
                                        value={produto}
                                        onChange={(event, newValue) => {
                                            if (itemError === 'produto') {
                                                setItemError('')
                                            }
                                            setProduto(newValue);
                                        }}
                                        inputValue={inputValue2}
                                        onInputChange={(event, newInputValue) => {
                                            setInputValue2(newInputValue);
                                        }}
                                        options={produtos}
                                        getOptionLabel={produto => produto.nome}
                                        noOptionsText="Sem opções"
                                        disableClearable
                                        renderInput={(params) => <TextField {...params} error={itemError === 'produto'} required label="Produto" variant="outlined" />}
                                    />

                                    <TextField
                                        className={classes.input}
                                        style={{ width: '30%' }}
                                        label="Quantidade"
                                        required
                                        fullWidth
                                        error={itemError === 'quantidade'}
                                        variant="outlined"
                                        value={quantidade}
                                        onChange={(e) => {
                                            if (itemError === 'quantidade') {
                                                setItemError('')
                                            }
                                            setQuantidade(e.target.value)
                                        }}
                                    />
                                </div>
                                <div style={{ display: 'flex' }}>

                                    <TextField
                                        className={classes.input}
                                        label="Descrição"
                                        fullWidth
                                        error={itemError === 'descricao'}
                                        variant="outlined"
                                        value={descricao}
                                        onChange={(e) => {
                                            if (itemError === 'descricao') {
                                                setItemError('')
                                            }
                                            setDescricao(e.target.value)
                                        }}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <AlertDiscardComponent open={confirmDiscard} cancel={setConfirmDiscard} clear={clearAll} />
            <AlertComponent
                open={openAlert}
                close={setOpenAlert}
                title={error.title}
                body={error.body}
                t={error.t}
            />
        </>
    );
}
