import React, { useState, useEffect, useRef } from 'react';

import {
    Typography,
    Container,
    Button,
    TextField,
    Avatar
} from '@material-ui/core'
import { FileUpload } from "primereact/fileupload";

import { formataCelular } from '@wiseinovacao/util'

import { updateUser, getUser } from '../../../../services/api'



import './styles.css'
import AlertComponent from './../../../../components/AlertComponent';

import {
    useHistory

} from 'react-router-dom'

function AdminProfileComponent({ loading }) {

    const history = useHistory()

    const [image, setImage] = React.useState();
    const [id, setID] = useState('')
    const [nome, setNome] = useState('')
    const [telefone, setTelefone] = useState('')
    const [email, setEmail] = useState('')
    const [cnpj, setCNPJ] = useState('')
    const [descricao, setDescricao] = useState('')
    const [facebook, setFacebook] = useState('')
    const [instagram, setInstragram] = useState('')
    const [cidade, setCidade] = useState('')
    const [whatsapp, setWhatsapp] = useState('')

    const [profile, setProfile] = useState()

    const [openAlert, setOpenAlert] = useState(false)
    const [itemError, setItemError] = useState('')
    const [error, setError] = useState({
        title: 'Error Title',
        body: 'Body Title',
        t: 'error'
    })


    const fileUploadRef = useRef(null);

    const onUpload = ({ files }) => {
        const [file] = files
        setImage(file)
        fileUploadRef.current.clear() //Limpa o arquivo da foto
    };

    useEffect(() => {
        const profile = JSON.parse(localStorage.getItem("profile"))
        setProfile(profile)
        setID(profile?.id)
        setNome(profile?.nome)
        setTelefone(profile?.telefone)
        setEmail(profile?.email)
        setCNPJ(profile?.cnpj)
        setDescricao(profile?.descricao)
        setFacebook(profile?.facebook)
        setInstragram(profile?.instagram)
        setCidade(profile?.cidade)
        setWhatsapp(profile?.whatsapp)
    }, [localStorage.getItem("profile")])


    async function handleUpdate() {

        if (!nome) {
            setItemError('nome')
            setError({
                title: 'Nome incorreto',
                body: 'É necessário informar um nome válido',
                t: 'error'
            })

            return setOpenAlert(true)

        }

        loading(true)
        let formData = new FormData()

        if (nome !== profile.nome) {
            formData.append('nome', nome)
        }

        if (cidade !== profile.cidade) {
            formData.append('cidade', cidade)
        }

        if (telefone !== profile.telefone) {
            formData.append('telefone', telefone)
        }
        if (whatsapp !== profile.whatsapp) {
            formData.append('whatsapp', whatsapp)
        }

        if (email !== profile.email) {
            formData.append('email', email)
        }

        if (descricao !== profile.descricao) {
            formData.append('descricao', descricao)
        }

        if (facebook !== profile.facebook) {
            formData.append('facebook', facebook)
        }

        if (instagram !== profile.instagram) {
            formData.append('instagram', instagram)
        }

        if (image) {
            formData.append('logo', image)
        }

        await updateUser(id, formData)
            .then(() => {
                setError({
                    title: 'Sucesso!',
                    body: 'Perfil atualizado com sucesso!',
                    t: 'success'
                })
                setOpenAlert(true)

            })
            .catch(() => {
                setError({
                    title: 'Erro ao atualizar :(',
                    body: 'Lamentamos o ocorrido - tente novamente em alguns instantes!',
                    t: 'error'
                })
                setOpenAlert(true)

            })
        await getUser()
            .then(res => {
                localStorage.setItem("profile", JSON.stringify(res.data[0]))
            })

        loading(false)
    }

    const chooseOptions = {
        icon: "pi pi-fw pi-image",
        label: "Editar foto",
        className: "custom-choose-btn p-button-rounded p-button-outlined"
    };

    return (
        <Container maxWidth="sm">
            <div className="card">

                <Typography variant="h4">Editar Perfil</Typography>
                <Avatar src={image?.objectURL ? image.objectURL : profile?.logo} alt={nome} id="image" />
                <FileUpload
                    ref={fileUploadRef}
                    mode="basic"
                    chooseOptions={chooseOptions}
                    customUpload
                    onSelect={onUpload}
                    uploadHandler={onUpload}
                    accept="image/*"
                />
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                    <TextField
                        style={{ margin: 6 }}
                        fullWidth
                        label="Nome"
                        error={itemError === 'nome'}
                        variant="outlined"
                        onChange={(e) => {
                            if (itemError === 'nome') {
                                setItemError('')
                            }
                            setNome(e.target.value)
                        }}
                        value={nome}
                    />
                    <TextField
                        style={{ margin: 6 }}
                        label="CNPJ"
                        fullWidth

                        variant="outlined"
                        disabled
                        value={cnpj}
                    />
                    <TextField
                        style={{ margin: 6 }}
                        label="Cidade"
                        fullWidth
                        variant="outlined"
                        value={cidade}
                        onChange={(e) => {
                            setCidade(e.target.value)
                        }}
                    />
                    <TextField
                        style={{ margin: 6 }}
                        label="Telefone"
                        fullWidth
                        value={telefone}
                        onChange={(e) => {
                            setTelefone(formataCelular(e.target.value))
                        }}
                        variant="outlined"
                    />
                    <TextField
                        style={{ margin: 6 }}
                        label="Whatsapp"
                        fullWidth
                        value={whatsapp}
                        onChange={(e) => {
                            setWhatsapp(formataCelular(e.target.value))
                        }}
                        variant="outlined"
                    />

                    <TextField
                        style={{ margin: 6 }}
                        label="E-mail"
                        value={email}
                        fullWidth
                        onChange={(e) => {
                            setEmail(e.target.value)
                        }}
                        variant="outlined"
                    />
                    <TextField
                        style={{ margin: 6 }}
                        label="Descrição da empresa"
                        value={descricao}
                        fullWidth
                        multiline
                        onChange={(e) => {
                            setDescricao(e.target.value)
                        }}
                        variant="outlined"
                    />
                    <TextField
                        style={{ margin: 6 }}
                        label="Facebook"
                        value={facebook}
                        fullWidth
                        multiline
                        onChange={(e) => {
                            setFacebook(e.target.value)
                        }}
                        variant="outlined"
                    />
                    <TextField
                        style={{ margin: 6 }}
                        label="Instagram"
                        value={instagram}
                        fullWidth
                        multiline
                        onChange={(e) => {
                            setInstragram(e.target.value)
                        }}
                        variant="outlined"
                    />

                </div>
            </div>
            <div id="div-button">
                <Button
                    variant="outlined"
                    color="secondary"
                    fullWidth
                    onClick={() => history.push('/admin?c=pedidos')}
                    style={{ margin: '12px' }}
                >
                    Cancelar
                </Button>
                <Button variant="outlined" color="primary" fullWidth style={{ margin: '12px' }} onClick={handleUpdate}>
                    Salvar
                </Button>

            </div>
            <AlertComponent
                open={openAlert}
                close={setOpenAlert}
                title={error.title}
                body={error.body}
                t={error.t}
            />
        </Container >

    );
}

export default AdminProfileComponent;