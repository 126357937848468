import React, { useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

import { getProdutos } from '../../../../services/api'

import Adicionar from './components/Adicionar'
import Editar from './components/Editar'
import './styles.css'

import { Image } from 'antd';

const screenWidth = window.innerWidth;

function AdminProdutoComponent({ session, loading }) {
    const [add, setAdd] = useState(false)
    const [editar, setEditar] = useState(false)

    const [item, setItem] = useState({})

    const [products, setProducts] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);

    async function Produtos() {
        loading(true)
        await getProdutos()
            .then(res => {
                setProducts(res.data)
            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    session(true)
                }
            })
        loading(false)

    }

    useEffect(() => {
        Produtos()
    }, [])

    const codigoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                {rowData.codigo_interno}
            </>
        );
    }

    const nomeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nome</span>
                {rowData.nome}
            </>
        );
    }

    const descricaoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descrição</span>
                {rowData.descricao}
            </>
        );
    }

    const ativoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ativo</span>
                <Chip
                    label={rowData.ativo ? "Sim" : "Não"}
                    style={{
                        color: rowData.ativo ? '#256029' : "#c63737",
                        backgroundColor: rowData.ativo ? '#c8e6c9' : "#ffcdd2"
                    }}
                />
            </>
        );
    }

    const mostrarPrecoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Mostrar preço</span>
                <Chip
                    label={rowData.mostrar_preco ? "Sim" : "Não"}
                    style={{
                        color: rowData.mostrar_preco ? '#256029' : "#c63737",
                        backgroundColor: rowData.mostrar_preco ? '#c8e6c9' : "#ffcdd2"
                    }}
                />
            </>
        );
    }

    const precoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Preço</span>
                {rowData.preco?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </>
        );
    }

    const quantidadeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Quantidade</span>
                {rowData.quantidade}
            </>
        );
    }

    const renderHeader = () => {
        return (
            <div className="table-header">
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Filtro global" />
                    </span>
                    <text>Total de {products?.length} produtos.</text>
                </div>
                <div>
                    <Button
                        style={{ backgroundColor: "#abd036", borderColor: '#abd036', height: 'max(36px)', marginLeft: '2px' }}
                        icon="pi pi-plus"
                        label={screenWidth > 420 ? "Produto" : ""}
                        onClick={() => setAdd(true)}
                    />

                </div>

            </div>
        );
    }

    const acaoTemplate = (item) => {
        return (


            <Button
                type="button"
                icon="pi pi-cog"
                tooltip="Editar"
                tooltipOptions={{ position: 'left' }}
                className="p-button-secondary"
                onClick={() => {
                    setItem(item)
                    setEditar(true)
                }}
            />

        );
    }

    const header = renderHeader();

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable
                    value={products}
                    className="p-datatable-responsive-demo"
                    paginator
                    rows={10}
                    header={header}
                    globalFilter={globalFilter}
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} cadastrados"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                >
                    <Column
                        field="imagem"
                        body={(item) => {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Image
                                        src={item.imagem}
                                        preview={{ mask: "Visualizar" }}
                                        alt={item.nome}
                                        height={80}
                                        width={80}
                                        style={{ objectFit: 'cover', borderRadius: 3 }}
                                    />
                                </div>
                            )
                        }}
                    />
                    <Column
                        field="codigo_interno"
                        header="Código"
                        sortable
                        filter
                        filterPlaceholder="Procurar por código"
                        body={codigoTemplate}
                    />
                    <Column
                        field="nome"
                        header="Nome"
                        filter
                        filterPlaceholder="Procurar por nome"
                        sortable
                        body={nomeTemplate}
                    />
                    <Column
                        field="descricao"
                        header="Descrição"
                        filter
                        filterPlaceholder="Procurar por descrição"
                        body={descricaoTemplate}
                    />
                    <Column
                        field="preco"
                        header="Preço"
                        sortable
                        filter
                        filterPlaceholder="Procurar por preço"
                        body={precoTemplate}
                    />
                    <Column
                        field="quantidade"
                        header="Quantidade"
                        sortable
                        filter
                        filterPlaceholder="Procurar por quantidade"
                        body={quantidadeTemplate}
                    />
                    <Column
                        field="ativo"
                        sortable
                        header="Ativo"
                        body={ativoTemplate}
                    />
                    <Column
                        field="mostrar_preco"
                        sortable
                        header="Mostrar Preço"
                        body={mostrarPrecoTemplate}
                    />
                    <Column
                        body={acaoTemplate}
                        headerStyle={{ width: '8em', textAlign: 'center' }}
                        bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                    />
                </DataTable>
            </div>
            <Adicionar open={add} close={setAdd} atualizar={Produtos} />
            <Editar open={editar} close={setEditar} atualizar={Produtos} item={item} />

        </div>
    );
}

export default AdminProdutoComponent;