import React, { useState, useEffect, useRef } from 'react';
import {
    Typography,
    Breadcrumbs,
    Dialog,
    DialogContent,
    TextField,
    Switch,
    FormControl,
    Grid,
    FormGroup,
    FormControlLabel,
    FormHelperText,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Checkbox,
    Button,
    Paper,
    Avatar

} from '@material-ui/core/';


import ToolbarAdd from '../../../../../../components/ToolbarAddComponent'
import AlertDiscardComponent from '../../../../../../components/AlertDiscardComponent';
import AlertComponent from '../../../../../../components/AlertComponent';
import { FileUpload } from "primereact/fileupload";


import './styles.css';

import { useStyles } from './styles.js';

import { getProdutos, createServico } from '../../../../../../services/api'
import { formataDinheiro } from '@wiseinovacao/util';


export default function Adicionar({ open, close, atualizar }) {
    const classes = useStyles();
    const [confirmDiscard, setConfirmDiscard] = useState(false)
    const fileUploadRef = useRef(null);


    const [image, setImage] = useState();


    const [nome, setNome] = useState('')
    const [descricao, setDescricao] = useState('')
    const [codigo, setCodigo] = useState('')
    const [ativo, setAtivo] = useState(true)
    const [mostrarValor, setMostrarValor] = useState(true)
    const [valor, setValor] = useState('0,00')

    const [openAlert, setOpenAlert] = useState(false)
    const [itemError, setItemError] = useState('')
    const [error, setError] = useState({
        title: 'Error Title',
        body: 'Body Title',
        t: 'error'
    })

    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState([]);
    const [right, setRight] = React.useState([]);
    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);


    async function Produtos() {
        await getProdutos()
            .then(res => {
                let itens = res.data.filter(p => p.ativo != false)
                setLeft(itens)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        Produtos()
    }, [])

    const onUpload = ({ files }) => {
        const [file] = files
        setImage(file)
        fileUploadRef.current.clear() //Limpa o arquivo da foto
    };

    async function adicionarServico() {
        if (!nome || nome === '' || nome.length < 3) {
            setItemError("nome")
            setError({
                title: 'Nome inválido',
                body: 'É necessário informar um nome valido para o serviço!',
                t: 'error'
            })

            return setOpenAlert(true)
        }
        if (!descricao || descricao === '' || descricao.length < 3) {
            setItemError("descricao")
            setError({
                title: 'Descrição inválida',
                body: 'É necessário informar uma descrição valida para o serviço!',
                t: 'error'
            })

            return setOpenAlert(true)
        }


        let ids = []
        right.map(item => {
            ids.push(item.id)
        })


        let _valor = String(valor)
        _valor = _valor.replace(".", "")
        _valor = _valor.replace(",", ".")

        if (!_valor) {
            setItemError("valor")
            setError({
                title: 'Valor inválido',
                body: 'É necessário informar um valor para o serviço!',
                t: 'error'
            })

            return setOpenAlert(true)
        }


        let formData = new FormData()
        formData.append('nome', nome)
        formData.append('descricao', descricao)
        formData.append('codigo_interno', codigo ? codigo : "-")
        formData.append('mostrar_preco', mostrarValor)
        formData.append('ativo', ativo)
        formData.append('valor', parseFloat(_valor))
        formData.append('imagem', image)

        formData.append('produtos', JSON.stringify(ids))


        await createServico(formData)
            .then(res => {
                atualizar()
                setError({
                    title: "Sucesso",
                    body: "Serviço adicionado com sucesso",
                    t: "success"
                })
                setOpenAlert(true)
                clearAll()
                close(false)
            })
            .catch(err => console.log(err))

    }

    function clearAll() {
        setConfirmDiscard(false)
        close(false)
        setNome('')
        setDescricao('')
        setCodigo('')
        setAtivo(true)
        setMostrarValor(true)
        setValor(0)
        setRight([])
        setImage()
    }


    function handleClose() {
        if (nome != '' || codigo != '' || valor != 0 || right.length > 0) {
            return setConfirmDiscard(true)
        } else {
            clearAll()
        }
    }


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };


    const handleAllRight = () => {
        setRight(right.concat(left));
        setLeft([]);
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };


    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAllLeft = () => {
        setLeft(left.concat(right));
        setRight([]);
    };

    function not(a, b) {
        return a.filter((value) => b.indexOf(value) === -1);
    }

    function intersection(a, b) {
        return a.filter((value) => b.indexOf(value) !== -1);
    }



    const customList = (items) => (
        <Paper className={classes.paper} elevation={3}>
            <List dense component="div" role="list">
                {items.map((value) => {
                    const labelId = `Transferir-item-${value.nome}`;

                    return (
                        <ListItem
                            key={value.id}
                            button
                            onClick={handleToggle(value)}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    color="primary"
                                    disableRipple
                                    inputProps={{ "aria-labelledby": labelId }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={value.nome} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Paper>
    );

    const chooseOptions = {
        icon: "pi pi-fw pi-image",
        label: "Adicionar foto",
        className: "custom-choose-btn p-button-rounded p-button-outlined"
    };


    return (
        <>
            <Dialog open={open} maxWidth="sm" fullWidth onClose={handleClose} >

                <ToolbarAdd title="Adicionar serviço" close={handleClose} save={adicionarServico} />

                <DialogContent style={{ backgroundColor: '#EFF2F5' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                        <div style={{ width: '100%' }}>
                            <Breadcrumbs aria-label="breadcrumb" style={{ color: '#3f51b5' }}>
                                <Typography style={{ color: '#a7a9aa' }}>Serviço</Typography>
                                <Typography style={{ color: '#a7a9aa' }}>{nome}</Typography>

                            </Breadcrumbs>
                            <div className={classes.divInformation}>
                                <Typography style={{ color: '#323c4b' }}>Dados do serviço</Typography>


                                <TextField
                                    className={classes.input}
                                    label="Nome do serviço"
                                    required
                                    error={itemError === 'nome'}
                                    variant="outlined"
                                    value={nome}
                                    onChange={(e) => {
                                        if (itemError === 'nome') {
                                            setItemError('')
                                        }
                                        setNome(e.target.value)
                                    }}
                                />
                                <TextField
                                    className={classes.input}
                                    label="Descrição do serviço"
                                    required
                                    error={itemError === 'descricao'}
                                    variant="outlined"
                                    value={descricao}
                                    onChange={(e) => {
                                        if (itemError === 'descricao') {
                                            setItemError('')
                                        }
                                        setDescricao(e.target.value)
                                    }}
                                />
                                <div style={{ width: '100%', display: 'flex' }}>


                                    <TextField
                                        className={classes.input}
                                        label="Código Interno"
                                        // required
                                        fullWidth
                                        error={itemError === 'codigo'}
                                        variant="outlined"
                                        value={codigo}
                                        onChange={(e) => {
                                            if (itemError === 'codigo') {
                                                setItemError('')
                                            }
                                            setCodigo(e.target.value)
                                        }}
                                    />

                                    <TextField
                                        className={classes.input}
                                        label="Valor"
                                        required
                                        fullWidth
                                        error={itemError === 'valor'}
                                        variant="outlined"
                                        value={valor}
                                        onChange={(e) => {
                                            if (itemError === 'valor') {
                                                setItemError('')
                                            }

                                            if (e.target.value === '0') {
                                                setValor('0,00')
                                            } else if (valor === "0,00" && e.target.value === '0,000') {
                                                setValor('0,00')
                                            } else {
                                                setValor(formataDinheiro(e.target.value))
                                            }
                                        }}
                                    />
                                </div>


                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: 12 }}>

                                    <div
                                        className={classes.input}
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                    >

                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={ativo}
                                                            color="primary"
                                                            onChange={() => setAtivo(!ativo)}
                                                            name="mostrar"
                                                        />
                                                    }
                                                    label="Serviço ativo"
                                                />
                                            </FormGroup>
                                            <FormHelperText>Quando ativo o serviço aparecerá ao usuário</FormHelperText>
                                        </FormControl>
                                        <FormControl component="fieldset">
                                            <FormGroup>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={mostrarValor}
                                                            color="primary"
                                                            onChange={() => setMostrarValor(!mostrarValor)}
                                                            name="mostrar"
                                                        />
                                                    }
                                                    label="Mostrar valor"
                                                />
                                            </FormGroup>
                                            <FormHelperText>Quando ativo será mostrado o valor ao solicitar o serviço</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Avatar variant="rounded" src={image?.objectURL} alt={nome} id="image" />

                                        <FileUpload
                                            ref={fileUploadRef}
                                            mode="basic"
                                            chooseOptions={chooseOptions}
                                            customUpload
                                            onSelect={onUpload}
                                            uploadHandler={onUpload}
                                            accept="image/*"
                                        />
                                    </div>
                                </div>

                                <Typography style={{ color: '#323c4b' }}>Produtos</Typography>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    className={classes.grid}
                                >
                                    <Grid item>{customList(left)}</Grid>
                                    <Grid item>
                                        <Grid container direction="column" alignItems="center" >
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classes.button}
                                                onClick={handleAllRight}
                                                disabled={left.length === 0}
                                                aria-label="Mover todos para a direita"
                                            >
                                                ≫
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classes.button}
                                                onClick={handleCheckedRight}
                                                disabled={leftChecked.length === 0}
                                                aria-label="Mover selecionado para a direita"
                                            >
                                                &gt;
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classes.button}
                                                onClick={handleCheckedLeft}
                                                disabled={rightChecked.length === 0}
                                                aria-label="Mover selecionado para a esquerda"
                                            >
                                                &lt;
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                size="small"
                                                className={classes.button}
                                                onClick={handleAllLeft}
                                                disabled={right.length === 0}
                                                aria-label="Mover todos para a esquerda"
                                            >
                                                ≪
                                            </Button>
                                        </Grid>
                                    </Grid>
                                    <Grid item>{customList(right)}</Grid>
                                </Grid>

                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <AlertDiscardComponent open={confirmDiscard} cancel={setConfirmDiscard} clear={clearAll} />
            <AlertComponent
                open={openAlert}
                close={setOpenAlert}
                title={error.title}
                body={error.body}
                t={error.t}
            />
        </>
    );
}
