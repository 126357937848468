import React from 'react';

import {
    useHistory,
} from "react-router-dom";




function AdminInicioComponent() {

    let history = useHistory()


    return (
        <div style={{ width: '100%', height: '80vh' }}>
            <text>AdminInicioComponent</text>
            {(() => { history.push('/admin?c=pedidos') })()}
        </div>
    );
}

export default AdminInicioComponent;