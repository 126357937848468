import React, { useState, useEffect } from 'react';
import {
    Typography,
    Breadcrumbs,
    Dialog,
    DialogActions,
    DialogContentText,
    DialogContent,
    TextField,
    DialogTitle,
    Slide,
    Button
} from '@material-ui/core/';

import { Divider, Image } from 'antd';

import ToolbarAdd from '../../../../../../components/ToolbarAddComponent'

import { useStyles } from './styles.js';
import { editarPedido } from '../../../../../../services/api'

import './styles.css';

import moment from 'moment'
import MomentUtils from "@date-io/moment";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';

import 'moment/locale/pt-br'
moment.locale('pt-br');
const screenWidth = window.innerWidth;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Editar({ open, close, atualizar, item, setAlertContext, setOpenAlert }) {
    const classes = useStyles();
    const [total, setTotal] = useState(0)

    const [openSecond, setOpenSecond] = useState()
    const [data, setData] = useState()

    const [itemError, setItemError] = useState('')
    const [motivoCancelamento, setMotivoCancelamento] = useState('')



    const [openPicker, setOpenPicker] = useState(false)
    const [option, setOption] = useState({
        accept: false,
        status: ''
    })

    useEffect(() => {
        let _total = 0;

        item?.produtos?.map(item => {
            _total += (item.valor * item.quantidade)
        })

        _total += item?.servico?.valor

        setTotal(_total)

    }, [item])

    function handleClose() {
        setOpenPicker(false)
        setOpenSecond(false)
        setMotivoCancelamento('')
        atualizar()
        close(false)
    }

    async function handleUpdate() {
        let _data
        if (option.accept) {

            if (!data) {
                setItemError('data')
                setAlertContext({
                    title: 'Data inválida',
                    body: 'Necessário informar uma data/hora diferente do momento atual!',
                    t: 'error'
                })

                return setOpenAlert(true)
            }

            _data = {
                id: item.id,
                status: 'andamento',
                data_entrega: data
            }
        } else {
            _data = {
                id: item.id,
                status: 'cancelado',
                motivo_cancelamento: motivoCancelamento

            }
        }

        await editarPedido(_data)
            .then(res => {
                atualizar()
                setAlertContext({
                    title: 'Sucesso!',
                    body: "Pedido foi atualizado com sucesso!",
                    t: 'success'
                })
                handleClose()
            })
            .catch(err => {
                setAlertContext({
                    title: '',
                    body: 'Ocorreu um erro ao atualizar o pedido. Eu lamento o ocorrido :(',
                    t: 'error'
                })
            })
        setOpenAlert(true)
    }

    const CancelarComponent = () => {
        return (
            <Button style={{ backgroundColor: "#e61919", borderColor: "#e61919", marginLeft: '3px', color: 'white' }} onClick={() => handleOpenSecondModal(false)} className="p-button">
                Cancelar
            </Button>
        )
    }


    const handleOpenSecondModal = (open) => {
        setOpenSecond(true)
        setOption({
            accept: open,
            status: open ? "Em andamento" : "Cancelado"
        })
    }

    return (
        <div>
            <Dialog open={open} maxWidth='md' fullWidth onClose={handleClose} >
                <ToolbarAdd
                    title={screenWidth > 600 && `Solicitação Nº ${item?.numero_pedido}`}
                    titleSalvar="Aceitar"
                    close={handleClose}
                    save={() => handleOpenSecondModal(true)}
                    Cancelar={CancelarComponent}
                    titleClose="Fechar"
                />
                <DialogContent style={{ backgroundColor: '#EFF2F5' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: '100%' }}>
                            <Breadcrumbs aria-label="breadcrumb" style={{ color: '#3f51b5' }}>
                                <Typography style={{ color: '#a7a9aa' }}>Pedido</Typography>
                                <Typography style={{ color: '#a7a9aa' }}>{item?.numero_pedido}</Typography>
                            </Breadcrumbs>
                            <div className={screenWidth > 600 ? classes.divInformation : classes.divInformationMobile}>
                                <Divider
                                    orientation="left"
                                    style={{ marginTop: 0, color: '#323c4b' }}
                                >
                                    <i className="pi pi-user" id="iconDivider" />
                                    Dados do cliente

                                </Divider>
                                <div style={{ display: 'flex', flexDirection: screenWidth < 600 && 'column' }}>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <TextField
                                            style={{ margin: 2 }}
                                            label="Nome"
                                            fullWidth
                                            value={item?.cliente?.nome}
                                        />
                                        <TextField
                                            label="Tipo"
                                            style={{ margin: 2 }}
                                            value={item?.cliente?.tipo === "fisica" ? "PF" : "PJ"}
                                        />
                                    </div>

                                    <div>
                                        <TextField
                                            fullWidth
                                            label={item?.cliente?.tipo === "fisica" ? "CPF" : "CNPJ"}
                                            style={{ margin: 2 }}
                                            value={item?.cliente?.identificacao}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: screenWidth < 600 && 'column' }}>

                                    <TextField
                                        style={{ margin: 2 }}
                                        label="E-Mail"
                                        fullWidth
                                        value={item?.cliente?.email}
                                    />
                                    <TextField
                                        style={{ margin: 2 }}
                                        label="Telefone"
                                        value={item?.cliente?.telefone}
                                    />
                                </div>

                                <div style={{ display: 'flex', }}>

                                    <TextField
                                        style={{ margin: 2 }}
                                        label="Endereço"
                                        multiline
                                        fullWidth
                                        value={
                                            `${item?.endereco?.rua}, ${item?.endereco?.numero}${item?.endereco?.complemento && `-${item?.endereco?.complemento}`}, ${item?.endereco?.bairro}, ${item?.endereco?.cidade} - ${item?.endereco?.estado} (${item?.endereco?.cep}) `
                                        }
                                    />
                                </div>
                                <Divider
                                    orientation="left"
                                    style={{ marginTop: 5, color: '#323c4b' }}
                                >
                                    <i className="pi pi-briefcase" id="iconDivider" />
                                    Serviço
                                </Divider>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div id="div-servico">

                                        <Image
                                            src={item.imagem}
                                            preview={{ mask: "Visualizar" }}
                                            alt={item.nome}
                                            height={screenWidth < 600 ? 60 : 80}
                                            width={screenWidth < 600 ? 60 : 80}
                                            style={{ objectFit: 'cover', borderRadius: 3, marginRight: '12px' }}
                                        />
                                        <div style={{ padding: 6 }}>
                                            <Typography variant={screenWidth > 600 ? "h5" : "caption"} component="h2">{`${item?.servico?.nome} (${item?.servico?.codigo_interno})`}</Typography>
                                            <Typography variant={screenWidth > 600 ? "body2" : "caption"} component="h2" color="textSecondary">
                                                {item?.servico?.descricao}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div style={{ padding: 12 }}>
                                        <Typography variant="body1" component="h2" color="textSecondary">
                                            {item?.servico?.valor?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </Typography>
                                    </div>
                                </div>
                                <Divider
                                    orientation="left"
                                    style={{ color: '#323c4b' }}
                                >
                                    <i className="pi pi-shopping-cart" id="iconDivider" />
                                    Produtos
                                </Divider>
                                {item?.produtos?.map(produto => {
                                    return (
                                        <div id="container-servico">
                                            <div id="div-servico">
                                                <Image
                                                    src={item.imagem}
                                                    preview={{ mask: "Visualizar" }}
                                                    alt={item.nome}
                                                    height={screenWidth < 600 ? 60 : 80}
                                                    width={screenWidth < 600 ? 60 : 80}
                                                    style={{ objectFit: 'cover', borderRadius: 3, marginRight: '12px' }}
                                                />
                                                <div style={{ padding: 6 }}>
                                                    <Typography variant={screenWidth > 600 ? "h5" : "caption"} component="h2">{`${produto.nome}`}</Typography>
                                                    <Typography variant={screenWidth > 600 ? "body2" : "caption"} component="h2" color="textSecondary">
                                                        {produto.descricao}
                                                    </Typography>
                                                    <Typography variant={screenWidth > 600 ? "body2" : "caption"} component="h2" color="textSecondary">
                                                        {produto.quantidade} x {produto.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </Typography>
                                                </div>
                                            </div>
                                            <div id="div-valor">
                                                <Typography variant={screenWidth > 600 ? "body1" : "caption"} component="h2" color="textSecondary">
                                                    {(produto.quantidade * produto.valor).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                </Typography>
                                            </div>
                                        </div>
                                    );
                                })}
                                <Divider
                                    orientation="right"
                                    style={{ color: '#323c4b', marginTop: 0 }}
                                >
                                    {/* <i className="pi pi-dollar" id="iconDivider" /> */}

                                </Divider>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: 12 }}>
                                    <text style={{ color: 'transparent' }}>.</text>
                                    <Typography variant={screenWidth > 600 ? "body1" : "caption"} component="h2" color="textSecondary">
                                        Total: {total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
            <Dialog

                open={openSecond}
                TransitionComponent={Transition}
                // keepMounted
                onClose={() => setOpenSecond(false)}
            >
                <DialogTitle >{option.accept ? "Qual a data e hora prevista para o serviço?" : "Cancelar o pedido?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {option.accept ?
                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'pt-br'}>
                                <KeyboardDateTimePicker
                                    fullWidth
                                    open={openPicker}
                                    onAccept={() => setOpenPicker(false)}
                                    onOpen={() => setOpenPicker(true)}
                                    onClose={() => {
                                        setOpenPicker(false)
                                    }}
                                    cancelText="Cancelar"
                                    inputVariant="outlined"
                                    format="DD/MM/yyyy hh:mm A"
                                    value={data}
                                    onChange={e => {
                                        if (itemError === "data") {
                                            setItemError('')
                                        }
                                        { e && e._d ? setData(e._d) : setData("") }
                                    }}
                                    invalidDateMessage="Formato de data inválido"
                                    invalidLabel="Data inválida"
                                    error={itemError === 'data'}
                                    cancelLabel="Cancelar"
                                    okLabel="Selecionar"
                                    okText="Selecionar"
                                    openTo="date"
                                    views={["date", "hours", "minutes"]}
                                    disableHighlightToday
                                    disablePast
                                />
                            </MuiPickersUtilsProvider>
                            :
                            <TextField

                                fullWidth
                                label="Motivo do cancelamento"
                                value={motivoCancelamento}
                                helperText={`${motivoCancelamento?.length}/30`}
                                onChange={event => {
                                    if (event.target.value.length <= 30) {
                                        setMotivoCancelamento(event.target.value)
                                    }

                                }}
                            />
                        }
                        <Typography variant="body1" component="h2" color="textSecondary" style={{ marginTop: '2%' }}>
                            Ao confirmar o status do pedido passará para "{option.status}" e a alteração não poderá ser desfeita.
                        </Typography>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSecond(false)} style={{ color: '#e61919' }}>
                        Voltar
                    </Button>
                    <Button onClick={handleUpdate} style={{ color: '#ABD036' }}>
                        Atualizar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
