import React from 'react';

import {
    Typography,
    Breadcrumbs,
    Link
} from '@material-ui/core'

import {
    useHistory,
} from "react-router-dom";


export default function Breadcrumb({ item }) {
    const history = useHistory()


    return (
        <Breadcrumbs aria-label="breadcrumb" style={{ paddingLeft: 12, paddingTop: 12 }}>
            <Link color="inherit" onClick={() => history.push('/admin?c=inicio')}>
                Início
            </Link>
            {item.map(element => {
                return (
                    <Typography color="textPrimary">
                        {element.title}
                    </Typography>
                )
            })}
            {/* <Link color="inherit" href="/getting-started/installation/" onClick={handleClick}>
                Core
            </Link> */}
            {/* <Typography color="textPrimary">Breadcrumb</Typography> */}


        </Breadcrumbs>
    );
}
