import React, { useEffect, useState } from 'react';

import {
    Divider,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
} from '@material-ui/core'
import { useStyles } from './styles'

export default function Review({ infos }) {
    const classes = useStyles();
    const [total, setTotal] = useState(0)

    useEffect(() => {
        let produtos = infos?.servico?.produtos
        let _total = 0
        produtos?.map(p => {
            if (p.mostrar_preco) {
                _total += (p.preco * p.quantidade)
            }
        })
        if (infos?.servico?.mostrar_preco) {
            _total += infos.servico.valor
        }
        setTotal(_total)
    }, [infos?.servico])


    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Revisão
            </Typography>
            <List disablePadding>
                <ListItem className={classes.listItem} key="serviço">
                    <img src={infos?.servico?.imagem} alt={infos?.servico?.nome} style={{ width: '90px', marginRight: 12, borderRadius: 3 }} />

                    <ListItemText primary={infos?.servico?.nome} secondary={infos?.servico?.descricao} />
                    {infos?.servico?.mostrar_preco && <Typography variant="body2">{infos?.servico?.valor.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</Typography>}
                </ListItem>
                <Divider />
                {infos?.servico?.produtos?.map((product) => (
                    <ListItem className={classes.listItem} key={product?.nome}>
                        <img src={product.imagem} alt={product.nome} style={{ width: '90px', marginRight: 12, borderRadius: 3 }} />
                        <ListItemText primary={product?.nome} secondary={product?.descricao} />
                        <Typography variant="body2">{`${product?.quantidade} x ${product?.mostrar_preco ? `${product?.preco.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}` : ""}`}</Typography>
                    </ListItem>
                ))}
                <ListItem className={classes.listItem}>
                    <ListItemText primary="Total *" />
                    <Typography variant="subtitle1" className={classes.total}>
                        {total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} *
                    </Typography>
                </ListItem>
            </List>
            <Typography variant="subtitle" color="textSecondary" className={classes.title}>
                É um valor estimado, podendo variar conforme a necessidade e disponibilidade dos produtos.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Dados do cliente
                    </Typography>
                    <Typography gutterBottom>{infos?.cliente?.nome}</Typography>
                    <Typography gutterBottom>{infos?.cliente?.tipo === "pf" ? "Pessoa física" : "Pessoa jurídica"} - {infos?.cliente?.identificacao}</Typography>
                    <Typography gutterBottom>{infos?.cliente?.email}</Typography>
                    <Typography gutterBottom>{infos?.cliente?.telefone}</Typography>
                </Grid>
                <Grid item container direction="column" xs={12} sm={6}>
                    <Typography variant="h6" gutterBottom className={classes.title}>
                        Endereço
                    </Typography>
                    <Grid container>
                        <Typography>{infos?.endereco?.rua}, {infos?.endereco?.numero}, {infos?.endereco?.bairro}</Typography>
                        <br />
                        <Typography >{infos?.endereco?.cidade}, {infos?.endereco?.estado} <br /> {infos?.endereco?.cep}</Typography>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}