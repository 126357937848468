import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography
} from '@material-ui/core'

function ConfirmComponent({ open, close, title, body, action, titleOk = "Confirmar", titleCancel = "Cancelar" }) {
    return (
        <Dialog
            open={open}
            fullScreen={false}
            onClose={() => close(false)}
        >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText >
                    <Typography>{body}</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => close(false)}
                    style={{ backgroundColor: '#eff2f5', borderColor: '#eff2f5', color: '#a7a9aa' }}
                >
                    {titleCancel}
                </Button>
                <Button
                    onClick={action}
                    style={{ backgroundColor: '#0c053e', borderColor: '#0c053e', color: 'white' }}
                >
                    {titleOk}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmComponent;