import React, { useState } from 'react';

import {
  Container,
  TextField,
  IconButton,
  Button
} from '@material-ui/core';

import { ThemeProvider } from "@material-ui/styles";

import AppbarComponent from '../../components/AppbarComponent'
import AlertComponent from '../../components/AlertComponent'
import Copyright from '../../components/CopyrightComponent'

import visibility from '../../assets/icons/visibility.svg'
import notVisibility from '../../assets/icons/notVisibility.svg'
import LogoLogin from '../../assets/images/login_wise_servico.svg'
import { getToken, getUser } from '../../services/api'

import { useHistory } from 'react-router-dom';

import { useStyles, theme } from './styles'
import './styles.css'



function Login() {
  const classes = useStyles()
  let history = useHistory()

  const [showPassword, setShowPassword] = useState(false)
  const [user, setUser] = useState(undefined)
  const [password, setPassword] = useState(undefined)
  const [openAlert, setOpenAlert] = useState(false)
  const [error, setError] = useState({
    title: 'Error Title',
    body: 'Body Title',
    t: 'error'
  })

  async function logar() {
    if (user && password) {
      await getToken(user, password)
        .then(async res => {
          localStorage.setItem('tokenAccess', res.data.access);
          await getUser()
            .then((res) => {
              localStorage.setItem('profile', JSON.stringify(res.data[0]))
              history.push("/admin")
            })
        })
        .catch(err => {
          if (err?.response?.status === 401) {
            setError({
              title: "Não é possível continuar",
              body:
                `Usuário ou senha incorreta. Verifique e tente novamente!`,
              t: "error"
            })
            setOpenAlert(true)
          }
        })
    } else {
      setError({
        title: "Não é possível continuar",
        body:
          `É necessário informar um usuário e uma senha!`,
        t: "error"
      })
      setOpenAlert(true)
    }
  }

  return (
    <div className={classes.root}>
      <AppbarComponent />
      <Container maxWidth="xs">
        <ThemeProvider theme={theme}>
          <img src={LogoLogin} className='Image-Login' alt='wise' />

          <form className={classes.form} noValidate>
            <TextField
              onKeyDown={(event) => event.key === 'Enter' ? logar() : undefined}
              inputProps={{
                autocapitalize: "none",
              }}
              onChange={text => setUser(text.target.value)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Usuário"
              name="email"
              autoComplete="username"
              autoFocus
            />

            <div id="password">
              <TextField
                onKeyDown={(event) => event.key === 'Enter' ? logar() : undefined}
                onChange={text => setPassword(text.target.value)}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
              />
            </div>
            <IconButton id="icon-password" onClick={() => setShowPassword(!showPassword)}>
              <img src={showPassword ? notVisibility : visibility} alt='mostrar senha' />
            </IconButton>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              style={{ marginTop: '3%' }}
              onClick={() => logar()}
            >
              Entrar
            </Button>
          </form>
        </ThemeProvider>
        <div style={{ marginTop: '30%' }}>
          <Copyright />

        </div>
      </Container>
      <AlertComponent
        open={openAlert}
        close={setOpenAlert}
        title={error.title}
        body={error.body}
        t={error.t}
      />
    </div >
  );
}

export default Login;