import React, { useState, useEffect, useContext, createContext, useRef } from 'react';
import {
    Typography,
    Dialog,
    DialogContentText,
    DialogContent,
    TextField,
    Radio,
    RadioGroup,
    Slide,
    FormControlLabel,
    DialogTitle,
    DialogActions,
} from '@material-ui/core/';

import { Divider, Image, Table, Input, Button, Form } from 'antd';

import ToolbarAdd from '../../../../../../components/ToolbarAddComponent'
import Adicionar from './components/Adicionar'


import { useStyles } from './styles.js';

import moment from 'moment'
import MomentUtils from "@date-io/moment";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

import 'moment/locale/pt-br'
import './styles.css';
import { formataDinheiro } from '@wiseinovacao/util';

import { editarPedido } from '../../../../../../services/api'


moment.locale('pt-br');
const screenWidth = window.innerWidth;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Concluir({ open, close, atualizar, item, setAlertContext, setOpenAlert, setItem }) {
    const classes = useStyles();
    const [total, setTotal] = useState(0)
    const [data, setData] = useState()
    const [openPicker, setOpenPicker] = useState(false)
    const [valor, setValor] = useState(0)
    const [desconto, setDesconto] = useState(0)
    const [tipoDesconto, setTipoDesconto] = useState('R$')
    const [valorDesconto, setValorDesconto] = useState(0)
    const [motivoCancelamento, setMotivoCancelamento] = useState('')


    const [itemError, setItemError] = useState('')

    const [openSecond, setOpenSecond] = useState()

    const [localItem, setLocalItem] = useState()

    const EditableContext = createContext(null);

    const [openAdicionarProduto, setOpenAdicionarProduto] = useState(false)


    useEffect(() => {
        if (tipoDesconto === "R$") {
            let _desconto = String(desconto)?.replace(".", "")
            setValorDesconto(_desconto?.replace(",", "."))
        } else {
            let _format = String(desconto);
            if (String(desconto).match(/,/)) {
                _format = _format.replace(".", "")
                _format = _format.replace(",", ".")
            }
            let _desconto = parseFloat(_format)
            _desconto = total * (_desconto / 100)

            setValorDesconto(_desconto)
        }

    }, [desconto])

    useEffect(() => {
        let _total = 0;

        item?.produtos?.map(item => {
            _total += (parseInt(item.quantidade) * parseFloat(item.valor_unitario))
        })

        _total += item?.servico?.valor

        setTotal(_total)
        setValor(_total)
        setLocalItem(item)
    }, [item])

    function handleClose() {
        setOpenPicker(false)
        setOpenSecond(false)
        atualizar()
        close(false)
    }




    async function handleUpdate() {

        if (valorDesconto > total) {
            setItemError('desconto')
            setAlertContext({
                title: '',
                body: 'O valor de desconto não pode ser maior que o valor total',
                t: 'error'
            })


            return setOpenAlert(true)
        }

        let _data = {
            id: item.id,
            status: 'concluido',
            data_conclusao: data ? data : new Date(),
            produtos: item.produtos,
            total: total,
            desconto: valorDesconto,
            valor_final: total - valorDesconto,
        }




        await editarPedido(_data)
            .then(res => {
                atualizar()
                setAlertContext({
                    title: 'Sucesso!',
                    body: "Pedido foi atualizado com sucesso!",
                    t: 'success'
                })
                handleClose()
            })
            .catch(err => {
                setAlertContext({
                    title: '',
                    body: 'Ocorreu um erro ao atualizar o pedido. Eu lamento o ocorrido :(',
                    t: 'error'
                })
            })
        setOpenAlert(true)


    }



    const handleDelete = (key, index) => {
        let _produtos = item?.produtos?.filter(element => item?.produtos?.indexOf(element) != index)


        setItem({
            ...item,
            produtos: _produtos
        })

    };


    const EditableRow = ({ index, ...props }) => {
        const [form] = Form.useForm();
        return (
            <Form form={form} component={false}>
                <EditableContext.Provider value={form}>
                    <tr {...props} />
                </EditableContext.Provider>
            </Form>
        );
    };


    const EditableCell = ({
        title,
        editable,
        children,
        dataIndex,
        record,
        handleSave,
        ...restProps
    }) => {
        const [editing, setEditing] = useState(false);
        const inputRef = useRef(null);
        const form = useContext(EditableContext);
        useEffect(() => {
            if (editing) {
                inputRef.current.focus();
            }
        }, [editing]);

        const toggleEdit = () => {
            setEditing(!editing);
            form.setFieldsValue({
                [dataIndex]: record[dataIndex]
            });
        };

        const save = async () => {
            try {
                const values = await form.validateFields();
                toggleEdit();
                handleSave({ ...record, ...values });
            } catch (errInfo) {
                console.log("Save failed:", errInfo);
            }
        };

        let childNode = children;

        if (editable) {
            childNode = editing ? (
                <Form.Item
                    style={{
                        margin: 0
                    }}
                    name={dataIndex}
                    rules={[
                        {
                            required: true,
                            message: `${title} is required.`
                        }
                    ]}
                >
                    <Input ref={inputRef} onPressEnter={save} onBlur={save} />
                </Form.Item>
            ) : (
                <div
                    className="editable-cell-value-wrap"
                    style={{
                        paddingRight: 24
                    }}
                    onClick={toggleEdit}
                >
                    {children}
                </div>
            );
        }

        return <td {...restProps}>{childNode}</td>;
    };

    const handleSave = (row, tipo) => {
        const newData = [...localItem.produtos]
        let index
        if (tipo === 'quantidade') {
            index = newData.findIndex((product) => row.id === product.id && row.valor_unitario === product.valor_unitario);
        } else if (tipo === 'valor_unitario') {
            index = newData.findIndex((product) => row.id === product.id && row.quantidade === product.quantidade);

            if (row.valor_unitario.match(/,/)) {
                row.valor_unitario = row.valor_unitario.replace(".", "")
                row.valor_unitario = row.valor_unitario.replace(",", ".")
            }
        }


        const item2 = newData[index];
        newData.splice(index, 1, { ...item2, ...row });

        setItem({
            ...item,
            produtos: newData
        })


    };

    const CancelarComponent = () => {
        return (
            <Button style={{ backgroundColor: "#e61919", borderColor: "#e61919", marginLeft: '3px', color: 'white', borderRadius: 3 }} onClick={() => handleOpenSecondModal(false)} className="p-button">
                Cancelar
            </Button>
        )
    }

    const handleOpenSecondModal = (open) => {
        setOpenSecond(true)

    }


    const handleCancel = async () => {

        let _data = {
            id: item.id,
            status: 'cancelado',
            motivo_cancelamento: motivoCancelamento
        }

        await editarPedido(_data)
            .then(res => {
                atualizar()
                setAlertContext({
                    title: 'Sucesso!',
                    body: "Pedido foi atualizado com sucesso!",
                    t: 'success'
                })
                handleClose()
            })
            .catch(err => {
                setAlertContext({
                    title: '',
                    body: 'Ocorreu um erro ao atualizar o pedido. Eu lamento o ocorrido :(',
                    t: 'error'
                })
            })
        setOpenAlert(true)
    }


    return (
        <div>
            <Dialog

                open={open}
                TransitionComponent={Transition}
                fullWidth
                maxWidth="lg"
                onClose={() => close(false)}
            >
                <ToolbarAdd
                    title={screenWidth > 600 && `Concluir solicitação Nº ${item?.numero_pedido}`}
                    titleSalvar="Finalizar"
                    save={handleUpdate}
                    close={handleClose}
                    titleClose="Fechar"
                    Cancelar={CancelarComponent}
                    titleClose="Fechar"
                />
                <DialogContent>
                    <DialogContentText>
                        <Typography>Cliente: {item?.cliente?.nome}</Typography>
                        {screenWidth < 600 && <Typography>Pedido Nº: {item?.numero_pedido}</Typography>}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '100%' }}>

                                <div className={classes.divInformation}>
                                    <Divider style={{ marginTop: 0, color: '#323c4b' }} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography>Valor </Typography>
                                        <TextField
                                            disabled
                                            style={{ width: screenWidth > 600 ? "15%" : "50%" }}
                                            value={valor?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        />

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                        <Typography>Desconto</Typography>
                                        <div style={{ display: "flex", justifyContent: 'flex-end', width: '100%' }}>
                                            <RadioGroup row value={tipoDesconto} onChange={(event) => setTipoDesconto(event.target.value)} >
                                                <FormControlLabel value="R$" control={<Radio />} label="R$" onChange={() => setDesconto(0)} />
                                                <FormControlLabel value="porcentagem" control={<Radio />} label="%" onChange={() => setDesconto(0)} />
                                            </RadioGroup>
                                            <TextField
                                                style={{ width: screenWidth > 600 ? "15.6%" : "50%" }}
                                                value={desconto}
                                                error={itemError === 'desconto'}
                                                onChange={e => {
                                                    if (itemError === 'desconto') {
                                                        setItemError('')
                                                    }
                                                    if (tipoDesconto === "R$") {
                                                        setDesconto(formataDinheiro(e.target.value))
                                                    } else {
                                                        setDesconto(e.target.value)
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography>Data de finalização</Typography>
                                        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'pt-br'}>
                                            <KeyboardDatePicker
                                                style={{ width: screenWidth > 600 ? "15%" : "50%" }}
                                                open={openPicker}
                                                onAccept={() => setOpenPicker(false)}
                                                onOpen={() => setOpenPicker(true)}
                                                onClose={() => {
                                                    setOpenPicker(false)
                                                }}
                                                cancelText="Cancelar"
                                                format="DD/MM/yyyy"
                                                value={data}
                                                onChange={e => {
                                                    { e && e._d ? setData(e._d) : setData("") }
                                                }}
                                                invalidDateMessage="Formato de data inválido"
                                                invalidLabel="Data inválida"
                                                cancelLabel="Cancelar"
                                                okLabel="Selecionar"
                                                okText="Selecionar"
                                                openTo="date"
                                                views={["date"]}
                                                disableHighlightToday
                                                disableFuture
                                            />
                                        </MuiPickersUtilsProvider>

                                    </div>
                                    <Divider style={{ color: '#323c4b', marginTop: 0 }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div id="div-servico">
                                            <Image
                                                src={item?.servico?.imagem}
                                                preview={{ mask: "Visualizar" }}
                                                alt={item?.nome}
                                                height={screenWidth < 600 ? 60 : 50}
                                                width={screenWidth < 600 ? 60 : 50}
                                                style={{ objectFit: 'cover', borderRadius: 3, marginRight: '12px' }}
                                            />
                                            <div style={{ padding: 6 }}>
                                                <Typography variant={screenWidth > 600 ? "h5" : "caption"} component="h2">{`${item?.servico?.nome} (${item?.servico?.codigo_interno})`}</Typography>
                                                <Typography variant={screenWidth > 600 ? "body2" : "caption"} component="h2" color="textSecondary">
                                                    {item?.servico?.descricao}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div style={{ padding: 12 }}>
                                            <Typography variant="body1" component="h2" color="textSecondary">
                                                {item?.servico?.valor?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => setOpenAdicionarProduto(true)}
                                            type="primary"
                                            style={{
                                                marginBottom: 16,
                                            }}
                                        >
                                            Adicionar
                                        </Button>
                                        <Table
                                            size={screenWidth < 600 && 'small'}
                                            scroll={{ x: 50 }}
                                            pagination={false}
                                            components={{
                                                body: {
                                                    row: EditableRow,
                                                    cell: EditableCell
                                                }
                                            }}
                                            rowClassName={() => 'editable-row'}
                                            bordered
                                            dataSource={item?.produtos}
                                            columns={[
                                                {
                                                    title: "Nome",
                                                    dataIndex: "nome",
                                                    // width: 500,
                                                    // fixed: 'left'
                                                },
                                                {
                                                    title: "Descrição",
                                                    dataIndex: "descricao",
                                                    responsive: ['sm'],
                                                },
                                                {
                                                    title: "Quantidade",
                                                    dataIndex: "quantidade",
                                                    editable: true,
                                                    width: screenWidth > 600 ? "15%" : "5%",

                                                    onCell: (record) => ({
                                                        record,
                                                        title: "Quantidade",
                                                        dataIndex: "quantidade",

                                                        editable: true,
                                                        handleSave: (row,) => handleSave(row, 'quantidade')
                                                    })
                                                },
                                                {
                                                    title: "Valor unitário",
                                                    dataIndex: "valor_unitario",
                                                    editable: true,
                                                    width: "15%",

                                                    render: (record) => <text>{parseFloat(record).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</text>,
                                                    onCell: (record) => ({
                                                        record,
                                                        title: "Valor",
                                                        dataIndex: "valor_unitario",
                                                        editable: true,
                                                        handleSave: (row) => handleSave(row, 'valor_unitario')
                                                    })

                                                },
                                                {
                                                    title: "",
                                                    dataIndex: "",
                                                    // fixed: 'right',

                                                    render: (_, record, index) => <a onClick={() => handleDelete(record, index)}>Apagar</a>
                                                }
                                            ]}
                                        />
                                    </div>

                                    <Divider style={{ color: '#323c4b', marginTop: 0 }} />

                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: 12 }}>
                                        <text style={{ color: 'transparent' }}>.</text>
                                        <div>
                                            <Typography variant="body1" component="h2" color="textSecondary">
                                                Total: {total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </Typography>
                                            <Typography variant="body1" component="h2" color="textSecondary">
                                                Desconto: {parseFloat(valorDesconto) ? parseFloat(valorDesconto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : "R$ 0,00"}
                                            </Typography>
                                            <Typography variant="body1" component="h2" color="textSecondary">
                                                Valor final: {(total - valorDesconto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>
            <Dialog

                open={openSecond}
                TransitionComponent={Transition}
                onClose={() => setOpenSecond(false)}
            >
                <DialogTitle >{"Cancelar o pedido?"}</DialogTitle>
                <DialogContent>
                    <TextField

                        fullWidth
                        label="Motivo do cancelamento"
                        value={motivoCancelamento}
                        helperText={`${motivoCancelamento?.length}/30`}
                        onChange={event => {
                            if (event.target.value.length <= 30) {
                                setMotivoCancelamento(event.target.value)
                            }

                        }}
                    />
                    <DialogContentText>
                        <Typography variant="body1" component="h2" color="textSecondary" style={{ marginTop: '2%' }}>
                            Ao confirmar o status do pedido passará para "Cancelado" e a alteração não poderá ser desfeita.
                        </Typography>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenSecond(false)} style={{ color: '#e61919' }}>
                        Voltar
                    </Button>
                    <Button onClick={handleCancel} style={{ color: '#ABD036' }}>
                        Atualizar
                    </Button>
                </DialogActions>
            </Dialog>
            <Adicionar
                open={openAdicionarProduto}
                close={setOpenAdicionarProduto}
                item={item}
                setItem={setItem}

            />
        </div >
    );
}
