import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography
} from '@material-ui/core'

import {
    useHistory,
} from "react-router-dom";

function SessionComponent({ open }) {

    const history = useHistory()


    return (
        <Dialog
            open={open}
            fullScreen={false}
            onClose={() => { }}
        >
            <DialogTitle>
                Sua sessão expirou!
            </DialogTitle>
            <DialogContent>
                <DialogContentText >
                    <Typography>Muito tempo passou e sua sessão expirou :(</Typography>
                    <Typography>É necessário fazer login novamente</Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        localStorage.removeItem("profile")
                        localStorage.removeItem("tokenAccess")
                        history.push("/login")
                    }}
                    style={{ backgroundColor: '#0c053e', borderColor: '#0c053e', color: 'white' }}
                >
                    Fazer Login
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default SessionComponent;