import React, { useState, useEffect } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';

import { getMovimentacoes } from '../../../../services/api'

import Adicionar from './components/Adicionar'
import './styles.css'
import { adicionaZero } from './../../../../utils/functions';

const screenWidth = window.innerWidth;

function AdminMovimentacaoComponent({ session, loading }) {
    const [add, setAdd] = useState(false)

    const [movimentacoes, setMovimentacoes] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);

    async function Movimentacoes() {
        loading(true)
        await getMovimentacoes()
            .then(res => {
                setMovimentacoes(res.data)
            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    session(true)
                }
            })
        loading(false)

    }

    useEffect(() => {
        Movimentacoes()
    }, [])

    const codigoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                {rowData.tipo[0].toUpperCase() + rowData.tipo.substr(1)}
            </>
        );
    }

    const nomeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nome</span>
                {rowData.produto.nome}
            </>
        );
    }

    const descricaoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descrição</span>
                {rowData.descricao}
            </>
        );
    }


    const quantidadeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Quantidade</span>
                {rowData.quantidade}
            </>
        );
    }

    const dataTemplate = (rowData) => {
        const data = new Date(rowData.data_movimentacao)

        return (
            <>
                <span className="p-column-title">Data de movimentação</span>
                {adicionaZero((data.getDate()).toString())}/{(adicionaZero(data.getMonth() + 1)).toString()}/{data.getFullYear()} - {adicionaZero(data.getHours())}:{adicionaZero(data.getMinutes())}:{adicionaZero(data.getSeconds())}
            </>
        );
    }


    const renderHeader = () => {
        return (
            <div className="table-header">
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Filtro global" />
                    </span>
                    <text>Total de {movimentacoes?.length} movimentações.</text>
                </div>
                <div>
                    <Button
                        style={{ backgroundColor: "#abd036", borderColor: '#abd036', height: 'max(36px)', marginLeft: '2px' }}
                        icon="pi pi-plus"
                        label={screenWidth > 420 ? "Movimentação" : ""}
                        onClick={() => setAdd(true)}
                    />
                </div>

            </div>
        );
    }

    const header = renderHeader();

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable
                    value={movimentacoes}
                    className="p-datatable-responsive-demo"
                    paginator
                    rows={10}
                    header={header}
                    globalFilter={globalFilter}
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} cadastrados"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                >
                    <Column
                        field="data_movimentacao"
                        header="Data de movimentação"
                        filter
                        filterPlaceholder="Procurar por data"
                        sortable
                        body={dataTemplate}
                    />
                    <Column
                        field="tipo"
                        header="Tipo"
                        sortable
                        filter
                        filterPlaceholder="Procurar por tipo"
                        body={codigoTemplate}
                    />
                    <Column
                        field="descricao"
                        header="Descrição"
                        filter
                        filterPlaceholder="Procurar por descrição"
                        body={descricaoTemplate}
                    />
                    <Column
                        field="produto.nome"
                        header="Produto"
                        sortable
                        filter
                        filterPlaceholder="Procurar por produto"
                        body={nomeTemplate}
                    />
                    <Column
                        field="quantidade"
                        header="Quantidade"
                        sortable
                        filter
                        filterPlaceholder="Procurar por quantidade"
                        body={quantidadeTemplate}
                    />
                </DataTable>
            </div>
            <Adicionar open={add} close={setAdd} atualizar={Movimentacoes} />
        </div>
    );
}

export default AdminMovimentacaoComponent;