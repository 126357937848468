import React, { useEffect, useState } from 'react';

import {
    Paper,
    Stepper,
    Step,
    StepLabel,
    Button,
    Typography,
    Link,
} from '@material-ui/core'

import ClienteForm from './components/ClienteForm'
import EnderecoForm from './components/EnderecoForm';
import ProdutoForm from './components/ProdutoForm';
import Review from './components/Revisao';

import { useStyles } from './styles'

import Copyright from '../../components/CopyrightComponent'
import AppBar from '../../components/AppbarComponent'

import {
    useLocation,
    useHistory
} from "react-router-dom";

import { getServicosEmpresa, addPedido } from '../../services/api'

import { validaCNPJ, validaCPF, validaEmail } from '@wiseinovacao/util'

import AlertComponent from '../../components/AlertComponent'
import AlertDiscardProdutoComponent from '../../components/AlertDiscardProdutoComponent'

import { Divider, Image } from 'antd'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const screenWidth = window.innerWidth;

export default function Checkout() {
    let query = useQuery();
    let history = useHistory()

    const classes = useStyles();
    const [infos, setInfos] = useState({})
    const [success, setSuccess] = useState()
    const steps = ['Cliente', 'Endereço', 'Produtos', 'Revisão'];

    const [descartarItem, setDescartarItem] = useState(false)

    const [error, setError] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState({
        title: "Title",
        body: "Body",
        t: 'success'
    })
    const [activeStep, setActiveStep] = useState(0);


    const nextStep = () => {
        let itens = infos.servico.produtos
        let _produtos = itens.filter(element => !element.isNew)
        setInfos({
            ...infos,
            servico: {
                ...infos.servico,
                produtos: _produtos
            }
        })
        setDescartarItem(false)
        setActiveStep(activeStep + 1);
    }


    const handleNext = () => {
        const { cliente, endereco } = infos

        if (activeStep === 0) {
            if (cliente?.nome === undefined || cliente.nome === '' || cliente.nome.length < 3) {
                setError('nome')
                setMessageAlert({
                    title: "Nome inválido",
                    body: "É necessário informar um nome válido",
                    t: 'error'
                })
                setOpenAlert(true)
                return
            }

            if (cliente?.email === undefined || cliente.email === '' || !validaEmail(cliente.email)) {
                setError('email')
                setMessageAlert({
                    title: "Email inválido",
                    body: "É necessário informar um email válido",
                    t: 'error'
                })
                setOpenAlert(true)
                return
            }
            let numberRegex = new RegExp(/(\(?\d{2}\)?\s)?(\d{4,5}\-\d{3,4})/g)

            if (cliente?.telefone === undefined || cliente.telefone === '' || !numberRegex.test(cliente.telefone)) {
                setError('telefone')
                setMessageAlert({
                    title: "Telefone inválido",
                    body: "É necessário informar um telefone válido",
                    t: 'error'
                })
                setOpenAlert(true)
                return
            }

            if (cliente?.tipo === undefined || cliente.tipo === '') {
                setError('tipo')
                setMessageAlert({
                    title: "Tipo inválido",
                    body: "É necessário informar o tipo de cliente",
                    t: 'error'
                })
                setOpenAlert(true)
                return
            }

            if (cliente?.tipo === 'pf') {
                let _cpf = cliente.identificacao.replace(".", "")
                _cpf = _cpf.replace("-", "")
                _cpf = _cpf.replace(".", "")
                if (!validaCPF(_cpf)) {
                    setError('identificacao')
                    setMessageAlert({
                        title: "CPF inválido",
                        body: "É necessário informar um CPF válido",
                        t: 'error'
                    })
                    setOpenAlert(true)
                    return
                }
            }

            if (cliente?.tipo === 'pj') {
                if (!validaCNPJ(cliente.identificacao)) {
                    setError('identificacao')
                    setMessageAlert({
                        title: "CNPJ inválido",
                        body: "É necessário informar um CNPJ válido",
                        t: 'error'
                    })
                    setOpenAlert(true)
                    return
                }
            }
            return setActiveStep(activeStep + 1);
        }
        if (activeStep === 1) {

            if (endereco?.cep === undefined || endereco.cep === '' || !/[0-9]{5}[\d]{3}/.test(endereco.cep)) {
                setError('cep')
                setMessageAlert({
                    title: "CEP inválido",
                    body: "É necessário informar um CEP válido",
                    t: 'error'
                })
                setOpenAlert(true)
                return
            }

            if (endereco?.rua === undefined || endereco.rua === '') {
                setError('rua')
                setMessageAlert({
                    title: "Endereço inválido",
                    body: "É necessário informar uma rua/avenida válida",
                    t: 'error'
                })
                setOpenAlert(true)
                return
            }

            if (endereco?.bairro === undefined || endereco.bairro === '') {
                setError('bairro')
                setMessageAlert({
                    title: "Endereço inválido",
                    body: "É necessário informar um bairro válido",
                    t: 'error'
                })
                setOpenAlert(true)
                return
            }

            if (endereco?.numero === undefined || endereco.numero === '') {
                setError('numero')
                setMessageAlert({
                    title: "Endereço inválido",
                    body: "É necessário informar um número válido",
                    t: 'error'
                })
                setOpenAlert(true)
                return
            }
            if (endereco?.cidade === undefined || endereco.cidade === '') {
                setError('cidade')
                setMessageAlert({
                    title: "Endereço inválido",
                    body: "É necessário informar uma cidade válida",
                    t: 'error'
                })
                setOpenAlert(true)
                return
            }

            if (endereco?.estado === undefined || endereco.estado === '') {
                setError('estado')
                setMessageAlert({
                    title: "Endereço inválido",
                    body: "É necessário informar uma estado válido",
                    t: 'error'
                })
                setOpenAlert(true)
                return
            }

            return setActiveStep(activeStep + 1);

        } else if (activeStep === 2) {
            let itens = infos.servico.produtos
            let _editando = false
            itens.forEach(element => {
                if (element.isNew) {
                    _editando = true
                }
            })
            if (_editando) {
                setDescartarItem(true)
            } else {
                setActiveStep(activeStep + 1);
            }

        } else if (activeStep === 3) {
            setActiveStep(activeStep + 1);
        }

    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    async function Servico() {

        await getServicosEmpresa(query.get('empresa'), query.get('servico'))
            .then(res => {
                let item = res.data[0].produtos

                item.forEach((element, index) => {
                    return item[index].quantidade = 1
                })

                setInfos({
                    ...infos,
                    servico: {
                        ...res.data[0],
                        produtos: item
                    },
                    empresa: query.get('empresa'),

                })
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        Servico()

    }, [query.get('servico'), query.get('empresa')])

    function getStepContent(step) {
        switch (step) {
            case 0:
                return <ClienteForm infos={infos} setInfos={setInfos} err={error} setErr={setError} />;
            case 1:
                return <EnderecoForm infos={infos} setInfos={setInfos} err={error} setErr={setError} />;
            case 2:
                return <ProdutoForm
                    infos={infos}
                    setInfos={setInfos}
                    setOpenAlert={setOpenAlert}
                    setMessageAlert={setMessageAlert}
                    error={error}
                    setError={setError}
                />;
            case 3:
                return <Review infos={infos} setInfos={setInfos} err={error} setErr={setError} />;
            default:
                throw new Error('Unknown step');
        }
    }

    async function handleAddPedido() {
        let _idProdutos = []
        let total = 0.0
        infos.servico.produtos.map(produto => {
            total += (parseInt(produto.quantidade) * parseFloat(produto.preco))

            _idProdutos.push({
                id: produto.id,
                quantidade: produto.quantidade,
            })
        })

        total += parseFloat(infos.servico.valor)

        const data = {
            cliente: infos.cliente,
            endereco: infos.endereco,
            produtos: _idProdutos,
            servico: { ...infos.servico, total }
        }

        console.log(data)


        await addPedido(data)
            .then(res => {
                setSuccess(res.data)
            })
            .catch(err => console.log(err))
    }

    return (
        <div>
            <AppBar />

            <main className={screenWidth > 600 ? classes.layout : classes.layoutMobile}>
                <Paper className={classes.paper}>
                    <Typography component="h1" variant="h4" align="center">
                        {activeStep === steps.length ? "Solicitação realizada" : "Solicitar serviço"}
                    </Typography>


                    {screenWidth > 600 && <Stepper activeStep={activeStep} className={classes.stepper}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>

                    }
                    <Divider />

                    <>
                        <div style={{ display: 'flex', justifyContent: 'start', padding: 12 }}>
                            <Image
                                src={infos?.servico?.imagem}
                                preview={{ mask: "Visualizar" }}
                                // alt={infos?.servico?.nome[0]}
                                height={50}
                                width={50}
                                style={{ objectFit: 'cover', borderRadius: 6, padding: 2, marginRight: '12px' }}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
                                <Typography component="body2">
                                    <b>{infos?.servico?.nome}</b>
                                </Typography>
                                <Typography component="caption" style={{ marginTop: -12 }}>
                                    {infos?.servico?.descricao}
                                </Typography>
                            </div>
                        </div>
                        <Divider />

                        {activeStep === steps.length ? (
                            <>
                                <Typography variant="h5" gutterBottom>
                                    Já recebemos sua solicitação.
                                </Typography>
                                <Typography variant="subtitle1">
                                    Seu número de solicitação é #<b>{success?.ordem?.numero_pedido}</b>. Em breve <b>{success?.ordem?.empresa}</b> entrará em contato.
                                    <br />
                                    <br />
                                    Você pode acompanhar o seu pedido {" "}
                                    <Link href={`#/consultar?empresa=${infos?.empresa}&?servico=${infos?.servico?.id}&num=${success?.ordem?.numero_pedido}`} onClick={() => history.push(`#/consultar?empresa=${infos?.empresa}&?servico=${infos?.servico?.id}&num=${success?.ordem?.numero_pedido}`)} variant="subtitle1">
                                        clicando aqui
                                    </Link>
                                    <br />
                                    <br />
                                    Também enviamos um e-mail para <b>{success?.ordem?.email_cliente}</b> com todos esses detalhes.
                                    <br />
                                    <br />
                                    Agradecemos pela confiança ;)
                                </Typography>
                            </>
                        ) : (
                            <>
                                {getStepContent(activeStep, infos, setInfos)}
                                <div className={classes.buttons}>
                                    {activeStep !== 0 && (
                                        <Button onClick={handleBack} className={classes.button}>
                                            Voltar
                                        </Button>
                                    )}
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => {
                                            if (activeStep === steps.length - 1) {
                                                handleAddPedido()
                                            }
                                            handleNext()
                                        }}
                                        className={classes.button}
                                    >
                                        {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                                    </Button>
                                </div>
                            </>
                        )}
                    </>
                </Paper>
                <Copyright />

                <AlertComponent
                    open={openAlert}
                    close={setOpenAlert}
                    title={messageAlert.title}
                    body={messageAlert.body}
                    t={messageAlert.t}
                />
                <AlertDiscardProdutoComponent open={descartarItem} cancel={setDescartarItem} clear={nextStep} />
            </main>
        </div>
    );
}