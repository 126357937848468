import React, { useState, useEffect } from 'react';
import {
    Typography,
    Dialog,
    DialogContentText,
    DialogContent,
    TextField,
    Slide,
} from '@material-ui/core/';

import { Divider, Image, Table } from 'antd';

import ToolbarAdd from '../../../../../../components/ToolbarAddComponent'

import { useStyles } from './styles.js';

import moment from 'moment'
import MomentUtils from "@date-io/moment";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker
} from '@material-ui/pickers';

import 'moment/locale/pt-br'
import './styles.css';
import { formataDinheiro } from '@wiseinovacao/util';

moment.locale('pt-br');
const screenWidth = window.innerWidth;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function VisualizarCancelado({ open, close, atualizar, item }) {
    const classes = useStyles();


    const [valor, setValor] = useState(0)
    const [motivo, setMotivo] = useState('')


    useEffect(() => {
        if (item.historico) {
            let _valor = parseFloat(item?.servicoJSON?.valor).toFixed(2)

            item?.produtos?.map(prod => {
                _valor += parseFloat(prod.valor) * prod.quantidade
            })

            setValor(_valor)
            setMotivo(item?.historico[item?.historico?.length - 1]?.motivo_cancelamento)
        }
    }, [item])

    function handleClose() {
        close(false)
        setMotivo('')
    }

    return (
        <div>
            <Dialog

                open={open}
                TransitionComponent={Transition}
                fullWidth
                maxWidth="lg"
                onClose={() => close(false)}
            >
                <ToolbarAdd
                    title={screenWidth > 600 && `Visualizar solicitação Nº ${item?.numero_pedido}`}
                    close={handleClose}
                    save={() => window.open(`https://wiseservico.wiseinovacao.com/#/consultar?empresa=${item?.clientewise?.id}&?servico=${item?.servico?.id}&num=${item?.numero_pedido}`, "_blank")}
                    titleClose="Fechar"
                    titleSalvar="Histórico"
                    color="#df6c6c"
                />
                <DialogContent>
                    <DialogContentText>
                        <Typography>Cliente: {item?.cliente?.nome}</Typography>
                        {screenWidth < 600 && <Typography>Pedido Nº: {item?.numero_pedido}</Typography>}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '100%' }}>

                                <div className={classes.divInformation}>
                                    <Divider style={{ marginTop: 0, color: '#323c4b' }} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography>Valor </Typography>
                                        {item?.historico &&
                                            <TextField
                                                disabled
                                                style={{ width: screenWidth > 600 ? "20%" : "50%" }}
                                                value={`R$ ${formataDinheiro(parseFloat(valor).toFixed(2))}`}
                                            />
                                        }

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <Typography>Motivo</Typography>

                                        <TextField
                                            style={{ width: screenWidth > 600 ? "20%" : "50%" }}
                                            value={motivo}
                                            disabled

                                        />
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography>Última atualização</Typography>
                                        {item?.historico &&
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'pt-br'}>
                                                <KeyboardDateTimePicker
                                                    style={{ width: screenWidth > 600 ? "20%" : "50%" }}
                                                    disabled
                                                    format="DD/MM/yyyy - HH:mm:ss"
                                                    value={item?.historico[item?.historico?.length - 1]['data']}
                                                />
                                            </MuiPickersUtilsProvider>
                                        }

                                    </div>
                                    <Divider style={{ color: '#323c4b', marginTop: 0 }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div id="div-servico">
                                            <Image
                                                src={item?.servico?.imagem}
                                                preview={{ mask: "Visualizar" }}
                                                alt={item?.nome}
                                                height={screenWidth < 600 ? 60 : 50}
                                                width={screenWidth < 600 ? 60 : 50}
                                                style={{ objectFit: 'cover', borderRadius: 3, marginRight: '12px' }}
                                            />
                                            <div style={{ padding: 6 }}>
                                                <Typography variant={screenWidth > 600 ? "h5" : "caption"} component="h2">{`${item?.servico?.nome} (${item?.servico?.codigo_interno})`}</Typography>
                                                <Typography variant={screenWidth > 600 ? "body2" : "caption"} component="h2" color="textSecondary">
                                                    {item?.servico?.descricao}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div style={{ padding: 12 }}>
                                            <Typography variant="body1" component="h2" color="textSecondary">
                                                R$ {formataDinheiro(parseFloat(item?.servicoJSON?.valor))}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <Table
                                            size={screenWidth < 600 && 'small'}
                                            scroll={{ x: 50 }}
                                            pagination={false}
                                            rowClassName={() => 'editable-row'}
                                            bordered
                                            dataSource={item?.produtos}
                                            columns={[
                                                {
                                                    title: "Nome",
                                                    dataIndex: "nome",

                                                },
                                                {
                                                    title: "Descrição",
                                                    dataIndex: "descricao",
                                                    responsive: ['sm'],
                                                },
                                                {
                                                    title: "Quantidade",
                                                    dataIndex: "quantidade",
                                                    width: screenWidth > 600 ? "15%" : "5%",
                                                },
                                                {
                                                    title: "Valor unitário",
                                                    dataIndex: "valor",
                                                    width: "15%",
                                                    render: (record) => <text>{parseFloat(record).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</text>,
                                                },

                                            ]}
                                        />
                                    </div>

                                    <Divider style={{ color: '#323c4b', marginTop: 0 }} />

                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: 12 }}>
                                        <text style={{ color: 'transparent' }}>.</text>
                                        <div>
                                            {item?.historico &&
                                                <>
                                                    <Typography variant="body1" component="h2" color="textSecondary">
                                                        Total: R$ {formataDinheiro(parseFloat(valor).toFixed(2))}
                                                    </Typography>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>


        </div >
    );
}
