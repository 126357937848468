import React from 'react';

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    Typography
} from '@material-ui/core'

function AlertDiscardComponent({ open, cancel, clear }) {
    return (
        <Dialog
            open={open}
            fullScreen={false}
            onClose={() => cancel(false)}
        >
            <DialogTitle>
                Deseja descartar?
                </DialogTitle>
            <DialogContent>
                <DialogContentText >
                    <Typography>Vi que você começou o cadastro, deseja descartar as alterações? </Typography>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => cancel(false)}
                    style={{ backgroundColor: '#eff2f5', borderColor: '#eff2f5', color: '#a7a9aa' }}
                >
                    Cancelar
                  </Button>
                <Button
                    onClick={clear}
                    style={{ backgroundColor: '#0c053e', borderColor: '#0c053e', color: 'white' }}
                >
                    Descartar
                  </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AlertDiscardComponent;