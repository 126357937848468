import React, { useRef, useState, useEffect } from 'react';

import {
    AppBar,
    Toolbar,
} from '@material-ui/core';

import {
    useHistory,
} from "react-router-dom";

import LogoWise from '../assets/images/wise_servico.svg'
import { PrimeIcons } from 'primereact/api';
import Avatar from '@material-ui/core/Avatar';

import { Menu } from 'primereact/menu';

import ConfirmComponent from '../components/ConfirmComponent'

import './styles.css'




function AppBarComponent({ Item = null, login = false }) {

    const menu = useRef(null);
    const [open, setOpen] = useState(false)
    const [alert, setAlert] = useState({})
    const [profile, setProfile] = useState({})

    let history = useHistory()
    const items = [
        {
            label: 'Início',
            disabled: true,
            icon: PrimeIcons.HOME,
            command: () => {
                history.push("/admin?c=inicio")
            }

        },
        {
            label: 'Pedidos',
            icon: PrimeIcons.TAGS,
            command: () => {
                history.push("/admin?c=pedidos")
            }

        },
        {
            label: 'Produtos',
            icon: PrimeIcons.SHOPPING_CART,
            items: [
                {
                    label: 'Produtos',
                    icon: PrimeIcons.SHOPPING_CART,
                    command: () => {
                        history.push("/admin?c=produtos&h=produtos")
                    }
                },
                {
                    separator: true
                },
                {
                    label: 'Movimentações',
                    icon: 'pi pi-fw pi-sort-alt',
                    command: () => {
                        history.push("/admin?c=produtos&h=movimentacoes")
                    }
                }
            ]
        },
        {
            label: 'Serviços',
            icon: PrimeIcons.BRIEFCASE,
            command: () => {
                history.push("/admin?c=servicos&h=servicos")
            }

        },
        {
            label: 'Agenda',
            icon: PrimeIcons.CALENDAR_PLUS,
            disabled: true,
            command: () => {
                history.push("/admin?c=agenda")
            }
        },
        {
            label: 'Relatórios',
            icon: PrimeIcons.CHART_BAR,
            disabled: true,

            command: () => {
                // history.push("/admin?c=campanha&h=campanhas")
            }
        },
    ];

    const itemProfile = [
        {
            label: 'Perfil',
            icon: 'pi pi-user',
            command: () => { history.push("/admin?c=perfil") }
        },
        {
            label: 'Sair',
            icon: 'pi pi-times',
            command: () => {
                setOpen(true)
                setAlert({
                    title: "Deseja sair?",
                    body: "Deseja sair e voltar a tela de login?"
                })
            }
        }
    ]

    useEffect(() => {
        let profile = localStorage.getItem('profile')
        setProfile(JSON.parse(profile))


    }, [localStorage.getItem('profile')])

    const logOut = () => {
        localStorage.removeItem('tokenAccess');
        history.push('/login')
    }

    const Profile = () => {
        return (
            <div style={{ marginRight: '2%' }}>
                <Menu model={itemProfile} popup ref={menu} id="popup_menu" />
                <Avatar alt={profile?.nome} src={profile?.logo} onClick={(event) => menu.current.toggle(event)} />
            </div>
        )

    }

    return (
        <>
            <AppBar position="static" style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#0c053e', alignItems: 'center' }}>
                <Toolbar>
                    <img src={LogoWise} alt='wise'
                        style={{ width: '130px' }}
                    />
                    {Item && <Item model={items} style={{ marginLeft: '20px', width: '100%' }} />}
                </Toolbar>
                {login && <Profile />}

            </AppBar>
            <ConfirmComponent
                open={open}
                close={setOpen}
                title={alert.title}
                body={alert.body}
                action={logOut}
            />
        </>
    );
}
export default AppBarComponent;