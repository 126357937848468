import React, { useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

import { getPedidos } from '../../../../services/api'

import Editar from './components/Editar'
import Concluir from './components/Concluir'
import Visualizar from './components/Visualizar'
import VisualizarCancelado from './components/VisualizarCancelado'

import AlertComponent from './../../../../components/AlertComponent';

import './styles.css'
import { adicionaZero } from './../../../../utils/functions';

function AdminListarPedidosComponent({ session, loading }) {
    const [services, setServices] = useState([])
    const [globalFilter, setGlobalFilter] = useState(null);
    const [item, setItem] = useState({})
    const [open, setOpen] = useState(false)
    const [concluir, setConcluir] = useState(false)

    const [visualizar, setVisualizar] = useState(false)
    const [visualizarCancelado, setVisualizarCancelado] = useState(false)

    const [openAlert, setOpenAlert] = useState(false)
    const [alertContext, setAlertContext] = useState({
        title: "title",
        body: "body",
        t: "error"
    })

    async function Pedidos() {
        loading(true)
        await getPedidos()
            .then(res => {
                setServices(res.data)
            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    session(true)
                }
            })
        loading(false)

    }

    useEffect(() => {
        Pedidos()
    }, [])


    const codigoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nº Pedido</span>
                {rowData.numero_pedido}
            </>
        );
    }

    const dataTemplate = (rowData) => {
        const data = new Date(rowData.data_pedido)
        return (
            <>
                <span className="p-column-title">Data de pedido</span>
                {adicionaZero(data.getDate())}/{adicionaZero(data.getMonth() + 1)}/{data.getFullYear()} - {adicionaZero(data.getHours())}:{adicionaZero(data.getMinutes())}:{adicionaZero(data.getSeconds())}
            </>
        );
    }

    const statusTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                <Chip
                    label={rowData.status[0].toUpperCase() + rowData.status.substr(1)}
                    style={{
                        color:
                            rowData.status === 'pendente' ? '#805b36'
                                : rowData.status === 'andamento' ? '#2a5b82'
                                    : rowData.status === 'concluido' ? '#256029'
                                        : rowData.status === 'cancelado' ? '#c63737'
                                            : undefined,
                        backgroundColor:
                            rowData.status === 'pendente' ? '#ffd8b2'
                                : rowData.status === 'andamento' ? '#b3e5fc'
                                    : rowData.status === 'concluido' ? '#c8e6c9'
                                        : rowData.status === 'cancelado' ? '#ffcdd2'
                                            : undefined
                    }}
                />
            </>
        );
    }

    const clienteTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cliente</span>
                {rowData.cliente.nome}

            </>
        );
    }

    const telefoneTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Telefone do cliente</span>
                {rowData.cliente.telefone}

            </>
        );
    }

    const servicoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Serviço</span>
                {rowData.servico.nome}

            </>
        );
    }

    const renderHeader = () => {
        return (
            <div className="table-header">
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Filtro global" />
                    </span>
                    <text>Total de {services?.length} pedidos cadastrados.</text>
                </div>


            </div>
        );
    }

    const acaoTemplate = (item) => {
        return (
            <Button
                type="button"
                tooltip={item.status === "andamento" ? "Finalizar" : item.status === "pendente" ? "Aceitar" : item.status === "concluido" ? "Visualizar" : ""}
                // disabled={item.status === "cancelado"}
                icon={item.status === "pendente" ? "pi pi-clock" : item.status === "concluido" ? "pi pi-book" : item.status === "cancelado" ? "pi pi-ban" : "pi pi-check-circle"}
                badgeClassName="p-badge-danger"
                className={
                    item.status === "pendente" ? "p-button-secondary" :
                        item.status === "andamento" ? "p-button-success" :
                            item.status === "concluido" ? "p-button-info" :
                                item.status === "cancelado" ? "p-button-danger" : undefined
                }
                onClick={() => {
                    setItem(item)
                    if (item.status === "andamento") {
                        setConcluir(true)
                    } else if (item.status === "concluido") {
                        setVisualizar(true)
                    } else if (item.status === "cancelado") {
                        setVisualizarCancelado(true)
                    } else {
                        setOpen(true)
                    }
                }}
            />

        );
    }

    const header = renderHeader();

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable
                    value={services}
                    className="p-datatable-responsive-demo"
                    paginator
                    rows={10}
                    header={header}
                    globalFilter={globalFilter}
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} cadastrados"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                >
                    <Column
                        field="numero_pedido"
                        header="Número do Pedido"
                        sortable
                        filter
                        filterPlaceholder="Procurar por número"
                        body={codigoTemplate}
                    />
                    <Column
                        field="data_pedido"
                        header="Data do pedido"
                        filter
                        filterPlaceholder="Procurar por data"
                        sortable
                        body={dataTemplate}
                    />
                    <Column
                        field="servico.nome"
                        header="Serviço"
                        filter
                        filterPlaceholder="Procurar por serviço"
                        sortable
                        body={servicoTemplate}
                    />
                    <Column
                        field="cliente.nome"
                        header="Cliente"
                        sortable
                        filter
                        filterPlaceholder="Procurar por cliente"
                        body={clienteTemplate}
                    />
                    <Column
                        field="cliente.telefone"
                        header="Telefone"
                        filter
                        filterPlaceholder="Procurar por cliente"
                        body={telefoneTemplate}
                    />

                    <Column
                        field="status"
                        header="Status"
                        filter
                        filterPlaceholder="Procurar por status"
                        body={statusTemplate}
                    />
                    <Column
                        body={acaoTemplate}
                        headerStyle={{ width: '8em', textAlign: 'center' }}
                        bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                    />
                </DataTable>
            </div>
            <Visualizar
                open={visualizar}
                close={setVisualizar}
                item={item}
                setItem={setItem}
            />

            <VisualizarCancelado
                open={visualizarCancelado}
                close={setVisualizarCancelado}
                item={item}
                setItem={setItem}
            />
            <Concluir
                open={concluir}
                close={setConcluir}
                item={item}
                setItem={setItem}
                atualizar={Pedidos}
                setAlertContext={setAlertContext}
                setOpenAlert={setOpenAlert}
            />
            <Editar
                open={open}
                close={setOpen}
                item={item}
                atualizar={Pedidos}
                setAlertContext={setAlertContext}
                setOpenAlert={setOpenAlert}
            />
            <AlertComponent
                open={openAlert}
                close={setOpenAlert}
                title={alertContext.title}
                body={alertContext.body}
                t={alertContext.t}
            />
        </div>
    );
}

export default AdminListarPedidosComponent;