import React, { useState, useEffect } from 'react';
import {
    Dialog,
    TextField,
    Slide,
    Typography,
} from '@material-ui/core/';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Divider, Image, Button, } from 'antd';


import { getProdutos } from '../../../../../../../../services/api'



import './styles.css';
import { formataDinheiro } from '@wiseinovacao/util';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Adicionar({ open, close, item, setItem }) {

    const [imagem, setImagem] = useState('')
    const [produtos, setProdutos] = useState([])

    const [produto, setProduto] = useState({
        id: 0,
        nome: 0,
        descricao: 0,
        quantidade: 0,
        valor_unitario: 0,
        valor: 0,
    })


    async function Produtos() {
        await getProdutos()
            .then(res => {
                setProdutos(res.data)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        Produtos()
    }, [])

    function handleClose() {
        setProduto({
            id: 0,
            nome: 0,
            descricao: 0,
            quantidade: 0,
            valor_unitario: 0,
            valor: 0,
        })
        close(false)
    }

    function handleAdd() {
        let _valor = String(produto.valor_unitario).replace(".", "")
        _valor = _valor.replace(",", ".")

        let _data = {
            ...produto,
            valor: produto.quantidade * _valor,
            valor_unitario: parseFloat(_valor)
        }

        setItem({
            ...item,
            produtos: [...item.produtos, _data]
        })


        handleClose()
    }


    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                fullWidth
                maxWidth="xs"
                onClose={handleClose}
            >
                <div
                    style={{ padding: 20, display: 'flex', flexDirection: 'column', alignItems: 'center' }}

                >
                    <Typography >Adicionar produto</Typography>
                    <Divider />
                    <Image
                        width={150}
                        height={150}
                        src={imagem}
                    />
                    <Autocomplete
                        options={produtos}
                        style={{ width: '80%', marginTop: 12 }}
                        onChange={(e, v) => {
                            if (v != null) {
                                setImagem(v.imagem)
                                setProduto({
                                    id: v.id,
                                    nome: v.nome,
                                    descricao: v.descricao,
                                    quantidade: 1,
                                    valor_unitario: v.preco,
                                    valor: v.preco,
                                })
                            }
                        }}
                        getOptionLabel={(produto) => produto.nome}
                        renderInput={
                            (params) => <TextField {...params} label="Produto" />}

                    />
                    <div style={{ display: 'flex', padding: 20, justifyContent: 'center' }}>
                        <TextField
                            label="Quantidade"
                            style={{ width: '40%', margin: 12 }}

                            value={produto?.quantidade}
                            onChange={(event) => {
                                setProduto({
                                    ...produto,
                                    quantidade: parseInt(event.target.value)
                                })
                            }}
                        />
                        <TextField
                            label="Valor"
                            style={{ width: '40%', margin: 12 }}

                            value={produto?.valor_unitario?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}
                            onChange={(event) => {
                                setProduto({
                                    ...produto,
                                    valor_unitario: formataDinheiro(event.target.value)
                                })
                            }}
                        />
                    </div>
                    <div style={{ display: 'flex' }}>
                        <Button
                            type="secondary"
                            onClick={handleClose}

                            style={{ color: "red", borderColor: "red", margin: 7 }}

                        >

                            Cancelar
                        </Button>
                        <Button
                            onClick={handleAdd}
                            type="secondary"
                            style={{ color: "green", borderColor: "green", margin: 7 }}
                        >
                            Adicionar
                        </Button>
                    </div >
                </div >

            </Dialog>
        </div >
    );
}
