import React, { useState, useEffect } from 'react';
import {
    Typography,
    Dialog,
    DialogContentText,
    DialogContent,
    TextField,
    Slide,
} from '@material-ui/core/';

import { Divider, Image, Table } from 'antd';

import ToolbarAdd from '../../../../../../components/ToolbarAddComponent'

import { useStyles } from './styles.js';


import moment from 'moment'
import MomentUtils from "@date-io/moment";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from '@material-ui/pickers';

import 'moment/locale/pt-br'
import './styles.css';
import { formataDinheiro } from '@wiseinovacao/util';

moment.locale('pt-br');
const screenWidth = window.innerWidth;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function Visualizar({ open, close, atualizar, item }) {
    const classes = useStyles();
    const [desconto, setDesconto] = useState(0)


    useEffect(() => {
        if (item.historico) {
            setDesconto(parseFloat(item?.historico[item?.historico?.length - 1]?.pedido?.desconto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }))
        }
    }, [item])

    function handleClose() {
        close(false)
    }

    return (
        <div>
            <Dialog

                open={open}
                TransitionComponent={Transition}
                fullWidth
                maxWidth="lg"
                onClose={() => close(false)}
            >
                <ToolbarAdd
                    title={screenWidth > 600 && `Visualizar solicitação Nº ${item?.numero_pedido}`}
                    close={handleClose}
                    save={() => window.open(`https://wiseservico.wiseinovacao.com/#/consultar?empresa=${item?.clientewise?.id}&?servico=${item?.servico?.id}&num=${item?.numero_pedido}`, "_blank")}
                    titleClose="Fechar"
                    titleSalvar="Histórico"
                    color="#a0b8a0"
                />
                <DialogContent>
                    <DialogContentText>
                        <Typography>Cliente: {item?.cliente?.nome}</Typography>
                        {screenWidth < 600 && <Typography>Pedido Nº: {item?.numero_pedido}</Typography>}
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '100%' }}>

                                <div className={classes.divInformation}>
                                    <Divider style={{ marginTop: 0, color: '#323c4b' }} />
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography>Valor </Typography>
                                        {item?.historico &&
                                            <TextField
                                                disabled
                                                style={{ width: screenWidth > 600 ? "20%" : "50%" }}
                                                value={parseFloat(item?.historico[item?.historico?.length - 1]?.pedido?.total).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            />
                                        }

                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>

                                        <Typography>Desconto</Typography>

                                        <TextField
                                            style={{ width: screenWidth > 600 ? "20%" : "50%" }}
                                            value={desconto}
                                            disabled

                                        />
                                    </div>

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography>Data de finalização</Typography>
                                        {item?.historico &&
                                            <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={'pt-br'}>
                                                <KeyboardDatePicker
                                                    style={{ width: screenWidth > 600 ? "20%" : "50%" }}
                                                    disabled
                                                    format="DD/MM/yyyy"
                                                    value={item?.historico[item?.historico?.length - 1]['Data de conclusão']}
                                                />
                                            </MuiPickersUtilsProvider>
                                        }

                                    </div>
                                    <Divider style={{ color: '#323c4b', marginTop: 0 }} />

                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div id="div-servico">
                                            <Image
                                                src={item?.servico?.imagem}
                                                preview={{ mask: "Visualizar" }}
                                                alt={item?.nome}
                                                height={screenWidth < 600 ? 60 : 50}
                                                width={screenWidth < 600 ? 60 : 50}
                                                style={{ objectFit: 'cover', borderRadius: 3, marginRight: '12px' }}
                                            />
                                            <div style={{ padding: 6 }}>
                                                <Typography variant={screenWidth > 600 ? "h5" : "caption"} component="h2">{`${item?.servico?.nome} (${item?.servico?.nome})`}</Typography>
                                                <Typography variant={screenWidth > 600 ? "body2" : "caption"} component="h2" color="textSecondary">
                                                    {item?.servico?.descricao}
                                                </Typography>
                                            </div>
                                        </div>
                                        <div style={{ padding: 12 }}>
                                            <Typography variant="body1" component="h2" color="textSecondary">
                                                R$ {formataDinheiro(item?.servicoJSON?.valor)}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <Table
                                            size={screenWidth < 600 && 'small'}
                                            scroll={{ x: 50 }}
                                            pagination={false}
                                            rowClassName={() => 'editable-row'}
                                            bordered
                                            dataSource={item?.produtos}
                                            columns={[
                                                {
                                                    title: "Nome",
                                                    dataIndex: "nome",

                                                },
                                                {
                                                    title: "Descrição",
                                                    dataIndex: "descricao",
                                                    responsive: ['sm'],
                                                },
                                                {
                                                    title: "Quantidade",
                                                    dataIndex: "quantidade",
                                                    width: screenWidth > 600 ? "15%" : "5%",
                                                },
                                                {
                                                    title: "Valor unitário",
                                                    dataIndex: "valor_unitario",
                                                    width: "15%",
                                                    render: (record) => <text>{parseFloat(record).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</text>,
                                                },

                                            ]}
                                        />
                                    </div>

                                    <Divider style={{ color: '#323c4b', marginTop: 0 }} />

                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: 12 }}>
                                        <text style={{ color: 'transparent' }}>.</text>
                                        <div>
                                            {item?.historico &&
                                                <>
                                                    <Typography variant="body1" component="h2" color="textSecondary">
                                                        Total: R$ {formataDinheiro(parseFloat(item?.historico[item?.historico?.length - 1]?.pedido?.total).toFixed(2))}
                                                    </Typography>
                                                    <Typography variant="body1" component="h2" color="textSecondary">
                                                        Desconto: {parseFloat(item?.historico[item?.historico?.length - 1]?.pedido?.desconto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </Typography>
                                                    <Typography variant="body1" component="h2" color="textSecondary">
                                                        Valor final: R$ {formataDinheiro(parseFloat(item?.historico[item?.historico?.length - 1]?.pedido?.total) - parseFloat(item?.historico[item?.historico?.length - 1]?.pedido?.desconto)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                    </Typography>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContentText>
                </DialogContent>

            </Dialog>


        </div >
    );
}
