import React, { useState, useEffect } from 'react';

import {
    Typography,
    Card,
    CardActions,
    CardContent,
    Button,
    Avatar,
    IconButton,
    Divider,
    Grid
} from '@material-ui/core';

import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';

import AppbarComponent from '../../components/AppbarComponent'
import Copyright from '../../components/CopyrightComponent';

import { Image } from 'antd'

import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import RoomIcon from '@material-ui/icons/Room';

import {
    useHistory,
    useLocation
} from "react-router-dom";

import { getServicosEmpresa, getEmpresa } from '../../services/api'

import { useStyles } from './styles'
import './styles.css'

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const screenWidth = window.innerWidth;

export default function ListarServicos() {
    let query = useQuery();
    const classes = useStyles()
    const history = useHistory()

    const [servicos, setServicos] = useState([])
    const [empresa, setEmpresa] = useState()

    async function Servicos(empresa) {
        await getServicosEmpresa(empresa)
            .then(res => {
                setServicos(res.data)
            })
            .catch(err => console.log(err))

        await getEmpresa(empresa)
            .then(res => {
                setEmpresa(res.data)
            })
            .catch(err => console.log(err))
    }

    useEffect(() => {
        let empresa = query.get('empresa')

        Servicos(empresa)
    }, [query.get('empresa')])

    return (
        <div >
            <AppbarComponent />

            {empresa &&
                <div
                    id={screenWidth < 600 ? "container-mobile" : "container"}>
                    <div elevation={1} id={screenWidth < 600 ? "card-empresa-mobile" : "card-empresa"}>
                        <Avatar alt={empresa.nome} src={empresa.logo} id={screenWidth < 600 ? "imagem-perfil-mobile" : "imagem-perfil"} />

                        <div>
                            <Typography variant="h5" component="h2">
                                {empresa.nome}
                            </Typography>
                            <div style={{ display: 'flex', marginBottom: '2px' }}>
                                <RoomIcon fontSize="small" color="action" />
                                <Typography variant="body2" component="h2" color="textSecondary">
                                    {empresa.cidade}
                                </Typography>
                            </div>
                            <Typography className={classes.pos} variant="body2" color="textSecondary">
                                CNPJ: {empresa.cnpj}
                            </Typography>
                            <div id="div-icons">
                                <div>
                                    <IconButton href={`https://api.whatsapp.com/send?phone=55${empresa.whatsapp ? empresa.whatsapp.replace(/[() -]/g, '') : '-'}`} target="_blank" size="small" style={{ color: "#34af23" }}>
                                        <WhatsAppIcon />
                                    </IconButton>
                                    <IconButton href={empresa.facebook} target="_blank" size="small" style={{ color: "#3b5998" }}>
                                        <FacebookIcon />
                                    </IconButton>
                                    <IconButton href={empresa.instagram} target="_blank" size="small" style={{ color: "#e95950" }}>
                                        <InstagramIcon />
                                    </IconButton>
                                    <IconButton href={`mailto:${empresa.email}`} target="_blank" size="small" style={{ color: 'grey' }}>
                                        <AlternateEmailIcon />
                                    </IconButton>
                                </div>

                                {screenWidth > 600 &&
                                    <div id="div-descricao">
                                        <Typography variant="body1">
                                            {empresa.descricao}
                                        </Typography>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    {screenWidth < 600 &&
                        <div id="div-descricao-mobile">

                            <Typography variant="body1">
                                {empresa.descricao}
                            </Typography>
                        </div>
                    }
                </div>
            }
            <Divider />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Grid
                    item
                    container
                    xs={12}
                    justify="center"
                // 

                >
                    {servicos.map(element => {
                        return (
                            <Card
                                className={classes.card}
                                id={screenWidth < 600 && "card-servicos-mobile"}
                            >
                                <Image
                                    src={element.imagem}
                                    preview={{ mask: "Visualizar" }}
                                    alt={element.nome}
                                    height={80}
                                    width={100}
                                    style={{ objectFit: 'cover', borderRadius: 6, padding: 2, marginTop: 5 }}
                                />
                                <CardContent >
                                    <Typography variant="h5" component="h2">
                                        {element.nome}
                                    </Typography>
                                    <Typography className={classes.pos} color="textSecondary">
                                        {element.descricao}
                                    </Typography>
                                    {element.mostrar_preco &&
                                        <Typography variant="body2" component="p">
                                            Valor do serviço: R$ {element.valor.toFixed(2).replace('.', ',')}
                                        </Typography>
                                    }
                                </CardContent>
                                <CardActions>
                                    <Button size="small" onClick={() => history.push(`/solicitar?empresa=${empresa.id}&servico=${element.id}`)}>Solicitar</Button>
                                    <Button size="small" onClick={() => history.push(`/consultar?servico=${element.id}`)}>Consultar</Button>
                                </CardActions>
                            </Card>
                        )
                    })}
                </Grid>
                <Copyright />
            </div>
        </div >
    );

}
