import React from 'react';

import {
    Grid,
    TextField,
    Typography,
    InputLabel
} from '@material-ui/core'

import { getCEP } from '../../../../services/api'

export default function EnderecoForm({ infos, setInfos, err, setErr }) {

    return (
        <>
            <Typography variant="h6" gutterBottom>
                Endereço
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} style={{ marginTop: 12 }}>
                    <InputLabel required error={err === 'cep'}>CEP</InputLabel>

                    <TextField
                        required
                        error={err === 'cep'}
                        inputProps={{ 'type': 'number' }}
                        fullWidth
                        autoComplete="family-name"
                        value={infos?.endereco?.cep}
                        onChange={async (e) => {

                            if (isNaN(e.target.value)) {
                                return
                            }


                            if (err === 'cep') {
                                setErr('')
                            }
                            let _cep = e.target.value
                            _cep = _cep.replace(".", "")
                            _cep = _cep.replace("-", "")


                            if (e.target.value.length < 9) {

                                let cep = e.target.value.replace(/[0-9]{2}.[0-9]{3}[\d]{3}/, "")

                                setInfos({
                                    ...infos, endereco: {
                                        ...infos.endereco,
                                        cep: cep
                                    }
                                })
                            } else {
                                return
                            }
                            if (/[0-9]{8}/gm.test(_cep)) {
                                await getCEP(_cep)
                                    .then(res => {
                                        if (res.data.ok) {
                                            setInfos({
                                                ...infos, endereco: {
                                                    ...infos.endereco,
                                                    rua: res.data.address,
                                                    cep: _cep,
                                                    cidade: res.data.city,
                                                    bairro: res.data.district,
                                                    estado: res.data.state
                                                }
                                            })
                                        }
                                    })
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel required error={err === "rua"}>Rua/Avenida</InputLabel>

                    <TextField
                        fullWidth
                        error={err === "rua"}
                        autoComplete="shipping address-line1"
                        value={infos?.endereco?.rua}
                        onChange={(e) => {
                            if (err === 'rua') {
                                setErr('')
                            }
                            setInfos({
                                ...infos, endereco: {
                                    ...infos.endereco,
                                    rua: e.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <InputLabel required error={err === "bairro"}>Bairro</InputLabel>

                    <TextField
                        error={err === "bairro"}
                        fullWidth
                        autoComplete="shipping address-line2"
                        value={infos?.endereco?.bairro}
                        onChange={(e) => {
                            if (err === 'bairro') {
                                setErr('')
                            }
                            setInfos({
                                ...infos, endereco: {
                                    ...infos.endereco,
                                    bairro: e.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel required error={err === "numero"}>Número</InputLabel>

                    <TextField
                        required
                        fullWidth
                        error={err === "numero"}
                        inputProps={{ 'type': 'number' }}
                        autoComplete="shipping address-level2"
                        value={infos?.endereco?.numero}
                        onChange={(e) => {
                            if (isNaN(e.target.value)) {
                                return
                            }

                            if (err === 'numero') {
                                setErr('')
                            }


                            setInfos({
                                ...infos, endereco: {
                                    ...infos.endereco,
                                    numero: e.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel>Complemento</InputLabel>

                    <TextField
                        fullWidth
                        value={infos?.endereco?.complemento}
                        onChange={(e) => {
                            if (e.target.value.length > 50) {
                                return
                            }

                            setInfos({
                                ...infos, endereco: {
                                    ...infos.endereco,
                                    complemento: e.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel required error={err === 'cidade'}>Cidade</InputLabel>

                    <TextField
                        error={err === 'cidade'}
                        required
                        id="zip"
                        name="zip"
                        // label="Cidade"
                        fullWidth
                        autoComplete="shipping postal-code"
                        value={infos?.endereco?.cidade}
                        onChange={(e) => {
                            if (err === 'cidade') {
                                setErr('')
                            }
                            setInfos({
                                ...infos, endereco: {
                                    ...infos.endereco,
                                    cidade: e.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel required error={err === 'estado'}>Estado</InputLabel>

                    <TextField
                        required
                        error={err === 'estado'}
                        id="country"
                        name="country"
                        // label="Estado"
                        fullWidth
                        autoComplete="shipping country"
                        value={infos?.endereco?.estado}
                        onChange={(e) => {
                            if (err === 'estado') {
                                setErr('')
                            }
                            setInfos({
                                ...infos, endereco: {
                                    ...infos.endereco,
                                    estado: e.target.value
                                }
                            })
                        }}
                    />
                </Grid>

            </Grid>
        </>
    );
}