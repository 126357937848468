import {
    HashRouter,
    Route,
    Switch,
    Redirect
} from "react-router-dom";

import AdminComponent from './pages/Admin'
import Login from './pages/Login'
import ListarServicos from './pages/ListarServicos'
import SolicitarServico from './pages/SolicitarServico'

import ConsultarServico from './pages/ConsultaServicos'

const Routes = () => (
    <HashRouter>
        <Switch>
            <Route exact path="/" >
                <Redirect to="/login" />
            </Route>
            <Route exact path="/admin">
                <AdminComponent />
            </Route>
            <Route exact path="/login">
                <Login />
            </Route>
            <Route exact path="/servicos">
                <ListarServicos />
            </Route>
            <Route exact path="/solicitar">
                <SolicitarServico />
            </Route>
            <Route exact path="/consultar">
                <ConsultarServico />
            </Route>
        </Switch>
    </HashRouter>
);

export default Routes;
