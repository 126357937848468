import { makeStyles, createMuiTheme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

    },
    input: {
        margin: '6px',
    },

    divInformation: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        borderRadius: '3px',
        padding: '12px',
        marginBottom: '12px',
    },
    paper: {
        width: 200,
        height: 230,
        overflow: "auto"
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
    grid: {
        margin: '6px',
        display: 'flex',
        width: '100%',
        alignContent: 'center',
        justifyContent: 'center',
    }
}));

export const theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#34af23',
        },
        primary: {
            main: '#0c053e'
        }
    },

});