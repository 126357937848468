import React, { useEffect, useState } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';

import { Image } from 'antd'

import Adicionar from './components/Adicionar'
import Editar from './components/Editar'

import { getServicos } from '../../../../services/api'

import './styles.css'

const screenWidth = window.innerWidth;
function AdminServicoComponent({ session, loading }) {

    const [add, setAdd] = useState(false)

    const [services, setServices] = useState([])
    const [globalFilter, setGlobalFilter] = useState(null);

    const [item, setItem] = useState({})
    const [editar, setEditar] = useState(false)


    async function Servicos() {
        loading(true)
        await getServicos()
            .then(res => {
                setServices(res.data)
            })
            .catch(err => {
                if (err?.response?.status === 401) {
                    session(true)
                }
            })
        loading(false)

    }

    useEffect(() => {
        Servicos()
    }, [])


    const codigoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Código</span>
                {rowData.codigo_interno}
            </>
        );
    }

    const nomeTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nome</span>
                {rowData.nome}
            </>
        );
    }

    const descricaoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Descrição</span>
                {rowData.descricao}
            </>
        );
    }

    const ativoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ativo</span>
                <Chip
                    label={rowData.ativo ? "Sim" : "Não"}
                    style={{
                        color: rowData.ativo ? '#256029' : "#c63737",
                        backgroundColor: rowData.ativo ? '#c8e6c9' : "#ffcdd2"
                    }}
                />
            </>
        );
    }

    const mostrarPrecoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Mostrar preço</span>
                <Chip
                    label={rowData.mostrar_preco ? "Sim" : "Não"}
                    style={{
                        color: rowData.mostrar_preco ? '#256029' : "#c63737",
                        backgroundColor: rowData.mostrar_preco ? '#c8e6c9' : "#ffcdd2"
                    }}
                />
            </>
        );
    }

    const precoTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Preço</span>
                {rowData.valor?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
            </>
        );
    }




    const renderHeader = () => {
        return (
            <div className="table-header">
                <div style={{ display: 'flex', flexDirection: 'column' }}>

                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Filtro global" />
                    </span>
                    <text>Total de {services?.length} serviços cadastrados.</text>
                </div>
                <div>
                    <Button
                        style={{ backgroundColor: "#abd036", borderColor: '#abd036', height: 'max(36px)', marginLeft: '2px' }}
                        icon="pi pi-plus"
                        label={screenWidth > 420 ? "Serviço" : ""}
                        onClick={() => setAdd(true)}
                    />
                </div>

            </div>
        );
    }

    const acaoTemplate = (item) => {
        return (


            <Button
                type="button"
                icon="pi pi-cog"
                tooltip="Editar"
                tooltipOptions={{ position: 'left' }}
                className="p-button-secondary"
                onClick={() => {
                    setItem(item)
                    setEditar(true)
                }}
            />

        );
    }

    const header = renderHeader();

    return (
        <div className="datatable-responsive-demo">
            <div className="card">
                <DataTable
                    value={services}
                    className="p-datatable-responsive-demo"
                    paginator
                    rows={10}
                    header={header}
                    globalFilter={globalFilter}
                    currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} cadastrados"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    rowsPerPageOptions={[10, 25, 50]}
                >
                    <Column
                        field="imagem"
                        body={(item) => {
                            return (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Image
                                        src={item.imagem}
                                        preview={{ mask: "Visualizar" }}
                                        alt={item.nome}
                                        height={80}
                                        width={80}
                                        style={{ objectFit: 'cover', borderRadius: 3 }}
                                    />
                                </div>
                            )
                        }}
                    />
                    <Column
                        field="codigo_interno"
                        header="Código"
                        sortable
                        filter
                        filterPlaceholder="Procurar por código"
                        body={codigoTemplate}
                    />
                    <Column
                        field="nome"
                        header="Nome"
                        filter
                        filterPlaceholder="Procurar por nome"
                        sortable
                        body={nomeTemplate}
                    />
                    <Column
                        field="descricao"
                        header="Descrição"
                        filter
                        filterPlaceholder="Procurar por descrição"
                        body={descricaoTemplate}
                    />
                    <Column
                        field="valor"
                        header="Valor"
                        sortable
                        filter
                        filterPlaceholder="Procurar por valor"
                        body={precoTemplate}
                    />
                    <Column
                        field="ativo"
                        sortable
                        header="Ativo"
                        body={ativoTemplate}
                    />
                    <Column
                        field="mostrar_preco"
                        sortable
                        header="Mostrar Preço"
                        body={mostrarPrecoTemplate}
                    />
                    <Column
                        body={acaoTemplate}
                        headerStyle={{ width: '8em', textAlign: 'center' }}
                        bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                    />
                </DataTable>
            </div>
            <Adicionar open={add} close={setAdd} atualizar={Servicos} />
            <Editar open={editar} close={setEditar} atualizar={Servicos} item={item} />

        </div>
    );
}

export default AdminServicoComponent;