import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
    card: {
        margin: '12px',
        padding: '6px',
        height: '120px'
    },
    title: {
        fontSize: 14,
    },
    divContent: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    listItem: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        padding: 12,
        margin: 2,
        border: 'thin  solid #ddd',
        borderRadius: 2
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    divListItem: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between'
    },
    divListItemMobile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'space-between'
    }

    // style={{ display: 'flex', width: '100%', justifyContent: 'space-between', }}


});