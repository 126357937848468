import React from 'react';

import {
    Grid,
    Typography,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@material-ui/core'

import { formataCelular, formataCNPJ, formataCPF } from '@wiseinovacao/util'

export default function ClienteForm({ infos, setInfos, err, setErr }) {
    return (
        <React.Fragment>
            <Typography variant="h6" gutterBottom>
                Dados do cliente
            </Typography>
            <Grid container spacing={3} style={{ marginTop: 12 }}>
                <Grid item xs={12}>
                    <InputLabel required error={err === 'nome'}>Nome</InputLabel>

                    <TextField
                        required
                        error={err === 'nome'}
                        fullWidth
                        value={infos?.cliente?.nome}
                        onChange={(e) => {
                            if (err === 'nome') {
                                setErr('')
                            }
                            setInfos({
                                ...infos, cliente: {
                                    ...infos.cliente,
                                    nome: e.target.value
                                }
                            })
                        }}
                        autoComplete="given-name"
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <InputLabel required error={err === 'email'}>Email</InputLabel>

                    <TextField
                        required
                        error={err === 'email'}
                        fullWidth
                        autoComplete="email"
                        type="email"
                        value={infos?.cliente?.email}
                        onChange={(e) => {
                            if (err === 'email') {
                                setErr('')
                            }
                            setInfos({
                                ...infos, cliente: {
                                    ...infos.cliente,
                                    email: e.target.value
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel required error={err === 'telefone'}>Telefone</InputLabel>

                    <TextField
                        fullWidth
                        error={err === 'telefone'}
                        value={infos?.cliente?.telefone}

                        onChange={(e) => {
                            if (err === 'telefone') {
                                setErr('')
                            }
                            setInfos({
                                ...infos, cliente: {
                                    ...infos.cliente,
                                    telefone: formataCelular(e.target.value)
                                }
                            })
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>

                    <FormControl fullWidth error={err === 'tipo'}>
                        <InputLabel required>Tipo</InputLabel>
                        <Select

                            value={infos?.cliente?.tipo}
                            onChange={(e) => {
                                if (err === 'tipo') {
                                    setErr('')
                                }
                                setInfos({
                                    ...infos, cliente: {
                                        ...infos.cliente,
                                        tipo: e.target.value,
                                        identificacao: ''
                                    }
                                })

                            }}
                        >
                            <MenuItem value="pf">Pessoa física</MenuItem>
                            <MenuItem value="pj">Pessoa jurídica</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <InputLabel required error={err === 'identificacao'}>{!infos?.cliente?.tipo ? "Identificação" : infos?.cliente?.tipo === "pf" ? "CPF" : "CNPJ"}</InputLabel>

                    <TextField
                        id="state"
                        disabled={!infos?.cliente?.tipo}
                        name="state"
                        error={err === 'identificacao'}
                        fullWidth
                        value={infos?.cliente?.identificacao}
                        onChange={(e) => {
                            if (err === 'identificacao') {
                                setErr('')
                            }
                            setInfos({
                                ...infos, cliente: {
                                    ...infos.cliente,
                                    identificacao: infos?.cliente?.tipo === "pf" ? formataCPF(e.target.value) : formataCNPJ(e.target.value)
                                }
                            })
                        }}
                    />
                </Grid>

            </Grid>
        </React.Fragment>
    );
}
